<template>
    <div>
        <base-task-status
            :session_id="session.id"
            task_name="administrative_acts"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            return ApiService
                .get(`/amas/analysis/analysis/api/${this.session.id}/acts`)
                .then(response => {
                    let acts = response.data;
                    let icon, bg, tooltip;
                    let url = `/amas/analysis/${this.session.id}/document/administrative-acts`;
                    if (acts.length > 0) {
                        icon = "fa fa-check";
                        bg = "success";
                        tooltip = `${acts.length} ${this.$t("task_details:status:administrative_acts")}`;
                    } else {
                        icon = "fa fa-close";
                        bg = "light";
                        tooltip = this.$t("amas:analysis:legal_acts:analysis_does_not_contain_any_parcel");
                    }
                    return `
                        <a href="${url}">
                            <span class="circle circle-sm bg-${bg}" data-toggle="tooltip" data-original-title="${tooltip}" href="/abcd">
                                <i class="fa ${icon}"></i>
                            </span>
                        </a>
                    `
                })
        }
    },
}
</script>