<template>

    <div style="min-height: 100px" class="property-iq__comparable-transactions-tab vld-parent">     
        <div class="panel panel-default">  
            <div class="panel-heading">
                {{ $t('property_iq:history:building_transaction_title')}} 
                
                <div class="pull-right m-r-10 building-transactions-filter-btn">
                    <a href="#" class="btn btn-block btn-outline btn-info btn-xs" :class="{active: months === null}" v-on:click.prevent="filterTransactionsByDate(null)"> {{$t('property_iq:history:filter:all_transactions:btn')}} </a>    
                </div>     
                <div class="pull-right m-r-10 wrapper building-transactions-filter-btn">
                    <a href="#" class="btn btn-block btn-outline btn-info btn-xs" :class="{active: months === 48}" v-on:click.prevent="filterTransactionsByDate(48)"> {{$t('property_iq:history:filter:four_years:btn')}} </a>  
                </div> 
                <div class="pull-right m-r-10 wrapper building-transactions-filter-btn">
                    <a href="#" class="btn btn-block btn-outline btn-info btn-xs" :class="{active: months === 24}" v-on:click.prevent="filterTransactionsByDate(24)"> {{$t('property_iq:history:filter:two_years:btn')}} </a>  
                </div>
                
            </div>
            <div v-if="transactions.length" class="panel-body">
            
                    {{ $t('property_iq:history:transaction_building')}}
                    <a href="https://www.arvio.si/zanesljivost-podatkov-o-sklenjenih-stransakcijah/" target="_blank">{{$t("general:more")}}</a> {{$t("legal:about_data_collection")}}.
        
            </div>
            <loading 
                :active="loading_transactions_table"
                :can-cancel="false" 
                :is-full-page="false"
            />
            <transactions-table
                v-if="transactions.length"
                :transactions="filteredTransactions"
                :all_transactions="indexedTransactions"
            />
            <div v-else-if="!loading_transactions_table" class="panel-body">
                <p>
                    {{ $t('property_iq:history:transaction_error')}}
                    <a href="https://www.arvio.si/zanesljivost-podatkov-o-sklenjenih-stransakcijah/" target="_blank">{{$t("general:more")}}</a> {{$t("legal:about_data_collection")}}.
                </p>
            </div>      
        </div> 
    </div>
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


import ApiService from "@/components/utils/api.service.js"

import PropertyBuildingTransactionHistoryTable from "./TableBuilding.vue"

export default {
    components: {
        "transactions-table": PropertyBuildingTransactionHistoryTable,
        Loading,
    },
    props:  {
        re_key: {
            type: String,
        },
    },
    data: function () {
        return {
            transactions: [],
            loading_transactions_table: false,
            today: new Date(),
            months: 24,       
        }
    },
    computed: {

    
        uniqueTransactions: function() {
            let transactions = Object.values(
                                this.transactions
                                .reduce((acc, obj) => ({ ...acc, [obj.transaction_id]: obj }), {}))
                                .sort((a, b) => {
                                    
                                    return this._parseDate(b.transaction_date) - this._parseDate(a.transaction_date);
                                })
            
            return transactions    
        },
        indexedTransactions: function() {
            return this.transactions
                        .map((transaction) => {
                            let i = transaction;                        
                            return i})
                        .sort((a, b) => {
                            return this._parseDate(b.transaction_date) - this._parseDate(a.transaction_date);
                        })
        },
        filteredTransactions: function(period) {
            let transactions = this.uniqueTransactions;

            if (this.months) {
                transactions = this.uniqueTransactions
                                    .filter(tr => this._parseDate(tr.transaction_date) >= this.getTimePeriodStart(period))
                                    .map((transaction) => {
                                        let i = transaction;
                                        return i})
                                    .sort((a, b) => {
                                        return this._parseDate(b.transaction_date) - this._parseDate(a.transaction_date);
                                    })
            }
            return transactions
        },

        
    },
    methods: {
        _checkBuildingID: function(re_key) {
            var re_key_formatted;
            var id_parts = re_key.split("-")
            
            // Parcel e.g. '123-321'
            if (id_parts.length==2) { 
                return re_key;
            } 
            // Unit e.g. '123-321-1'
            else if (id_parts.length==3) {
                re_key_formatted = re_key.slice(0, re_key.lastIndexOf('-'));
                return re_key_formatted
            }
            else {
                throw {toString() { return "The building ID in not in the correct format (e.g. 123-321)"; } };
            }        
        },
        _parseDate: function(date_str) {
            var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
            var dt = new Date(date_str.replace(pattern,'$3-$2-$1'));
            return dt;
        },
        getTransactions: function() {
            this.loading_transactions_table = true;
            ApiService
                .get("/market/api/search", {
                    params: { building_id: this._checkBuildingID(this.re_key) }
                })
                .then(response => {
                    this.transactions = response.data;
                    this.loading_transactions_table = false;
                })
                .catch(error => {
                    this.loading_transactions_table = false;
                    throw error;
                })
        },
        getTimePeriodStart: function() {
            let startDate = new Date();
            startDate = new Date(startDate.setMonth(startDate.getMonth() - this.months))
            
            return startDate      
        },
        filterTransactionsByDate: function(months) {
            this.months = months;
        } ,
    },
    mounted() {
        this.getTransactions();
    }
}
</script>

<style scoped>

 .building-transactions-filter-btn .active {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
</style> 
