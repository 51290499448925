<template>
    <div class="dashboard-analytics-container">
        <loading 
            :active="showLoading"
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div class="row">
            <!--
            LEFT COLUMN - Map and dynamic widgets
            -->
            <div class="col-md-5 p-0">
                <div class="col-lg-12">
                    <div class="white-box dashboard-graph">
                        <!-- <h3 class="box-title">Izbrana regija: <span class="text-normal">{{selectedRegion}}</span></h3> -->
                        <div class="region-select-container" v-if="regionOptions.length > 1">
                            <div
                                v-for="regionOption in regionOptions"
                                :key="regionOption.id"
                                @click="region = regionOption"
                                :class="{'selected': regionOption.id == region.id}"
                            >
                                {{ $t(regionOption.name) }}
                            </div>
                        </div>
                        <clusterable-map
                            v-if="region && startingLevel"
                            class="row"
                            :re_type="2"
                            :getRegionFromParent="true"
                            :regionFromParent="region"
                            :startingLevel="startingLevel"
                        />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div v-if="isOpenAIEnabled()" class="white-box dashboard-graph">
                        <h3 class="box-title">{{ $t("dashboard:market_analysis") }} {{ selectedRegion }}</h3>
                        <loading 
                            class="dashboard-graph"
                            :active="marketComment.length === 0"
                            :can-cancel="false"
                            :is-full-page="false"
                        />
                        <p class="market-comment">{{ marketComment }}</p>
                    </div>
                </div>
                <div class="col-lg-12">
                    <!-- <dashboard-graph
                        v-if="checkShowGraph('avg_selling_price_m2')"
                        :title="`Cene, ${ $t('dashboard:apartments') }, ${selectedRegion}`"
                        :graphs="buildGraphData(['line',], ['avg_selling_price_m2',], true)"
                        :apiData="this.graphData['avg_selling_price_m2']"
                        :showGrowthOverUnits="true"
                        :displayUnit="'€/m²'"
                    /> -->
                    <dashboard-graph
                        v-if="checkShowGraph('arvio_ppi')"
                        :title="`${ $t('dashboard:price_index') }, ${ $t('dashboard:apartments') }, ${selectedRegion}`"
                        :graphs="buildGraphData(['line',], ['arvio_ppi',], true)"
                        :apiData="this.graphData['arvio_ppi']"
                        :showGrowthOverUnits="true"
                        displayUnit=""
                    />
                </div>
                <div class="col-lg-12">
                    <dashboard-graph
                        v-if="checkShowGraph('sold_volume')"
                        :title="`${ $t('dashboard:sold_volume') }, ${ $t('dashboard:apartments') }, ${selectedRegion}`"
                        :graphs="buildGraphData(['area',], ['sold_volume',])"
                        :apiData="this.graphData['sold_volume']"
                        :showGrowthOverUnits="true"
                    />
                </div>
                <div class="col-lg-4 col-12">
                    <dashboard-graph
                        v-if="checkShowGraph('sold_volume')"
                        :title="$t('dashboard:sales')"
                        :subtitle="`${ $t('dashboard:apartments') }, ${selectedRegion}`"
                        :description="$t('dashboard:sales:description')"
                        :graphs="buildGraphData([], ['sold_volume',])"
                        :apiData="this.graphData['sold_volume']"
                    />
                </div>
                <div class="col-lg-4 col-12">
                    <dashboard-graph
                        v-if="checkShowGraph('med_selling_price_m2')"
                        :title="$t('dashboard:prices')"
                        :subtitle="`${ $t('dashboard:apartments') }, ${selectedRegion}`"
                        :description="$t('dashboard:prices_median:description')"
                        :graphs="buildGraphData([], ['med_selling_price_m2',])"
                        :apiData="this.graphData['med_selling_price_m2']"
                        :displayUnit="'€/m²'"
                    />
                </div>
                <div class="col-lg-4 col-12">
                    <dashboard-graph
                        v-if="checkShowGraph('sum_selling_price')"
                        :title="$t('dashboard:sum_selling_price')"
                        :subtitle="`${ $t('dashboard:apartments') }, ${selectedRegion}`"
                        :description="$t('dashboard:sum_selling_price:description')"
                        :graphs="buildGraphData([], ['sum_selling_price',])"
                        :apiData="this.graphData['sum_selling_price']"
                        :displayUnit="'mio\xa0€'"
                    />
                </div>
                <!-- <div class="col-lg-12">
                    <dashboard-graph
                        v-if="checkShowGraph('med_selling_price_m2_advertisement')"
                        :title="`${ $t('dashboard:avg_selling_price') }, ${selectedRegion}`"
                        :graphs="buildGraphData(['line',], ['med_selling_price_m2_advertisement',], true)"
                        :apiData="this.graphData['med_selling_price_m2_advertisement']"
                        :showGrowthOverUnits="true"
                        :displayUnit="'€/m²'"
                    />
                </div> -->
                <!-- <div class="col-lg-12">
                    <dashboard-graph
                        v-if="checkShowGraph('advertisement_count')"
                        :title="`${ $t('dashboard:advertisement_count') }, ${selectedRegion}`"
                        :graphs="buildGraphData(['area',], ['advertisement_count',])"
                        :apiData="this.graphData['advertisement_count']"
                        :showGrowthOverUnits="true"
                    />
                </div> -->
                <div class="col-lg-12">
                    <dashboard-graph
                        v-if="checkShowGraph('type_listing')"
                        :title="`${ $t('dashboard:type_listing') }, ${this.baseRegion.name}`"
                        :graphs="buildGraphData(['donut',], ['type_listing',])"
                        :apiData="this.graphData['type_listing']"
                        :showValuesTable="true"
                    />
                </div>
                <!-- <div class="col-lg-12">
                    <dashboard-graph
                        v-if="checkShowGraph('adverts_per_household')"
                        :title="`${ $t('dashboard:adverts_per_1000_households') }, ${selectedRegion}`"
                        :description="$t('dashboard:adverts_per_1000_households:description')"
                        :graphs="buildGraphData([], ['adverts_per_household',])"
                        :apiData="this.graphData['adverts_per_household']"
                        :showGrowthOverUnits="true"
                    />
                </div> -->

                <div class="col-md-12">
                    <div class="white-box table-responsive transaction-table" v-if="transactions.length !== 0">
                        <h3 class="box-title">{{ $t("dashboard:last_10_transactions") }}, {{selectedRegion}}</h3>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{{ $t("general:address") }}</th>
                                    <th>{{ $t("analysis:ct:contract:date") }}</th>
                                    <th>{{ $t("analysis:ct:contract:value") }}</th>
                                    <th>{{ $t("general:area") }}</th>
                                    <th>{{ $t("general:year:built") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <loading 
                                    :active="loadingTransactions"
                                    :can-cancel="false" 
                                    :is-full-page="false"
                                />
                                <tr v-for="transaction in transactions" :key="transaction.id">
                                    <td>{{transaction.address}} <br />
                                      <span data-toggle="tooltip" data-original-title="Podrobnosti o transakciji" rel="tooltip">
                                        <a :href="'/market/transaction/'+transaction.transaction_id+'/details'" class="open-modal">
                                        #{{transaction.transaction_id}}
                                      </a></span>
                                    </td>
                                    <td>{{transaction.transaction_date}}</td>
                                    <td>
                                        <div class="text-info">{{transaction.transaction_amount|formatCurrency(0)}}</div>
                                        <div class="text-primary">{{transaction.transaction_amount_m2|formatCurrencym2(0)}}</div>
                                    </td>
                                    <td><span style="font-weight:400">{{transaction.contract_size|formatSize()}}</span></td>
                                    <td>{{transaction.building_year_built}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!--
            RIGHT COLUMN - Static widgets and news
            -->
            <div class="col-md-7 p-l-5">
                <div class="row m-l-5 m-r-5">
                    <div class="static-widgets">
                        <dashboard-graph
                            v-if="checkShowGraph('inflation')"
                            :title="$t('dashboard:inflation')"
                            :description="`${ $t('dashboard:inflation:description') }, `"
                            :graphs="buildGraphData([], ['inflation',])"
                            :apiData="this.graphData['inflation']"
                            :growthOverText="$t('dashboard:inflation:growth_over')"
                            displayUnit="%"
                            :decimalPlaces="1"
                        />
                        <dashboard-graph
                            v-if="checkShowGraph('gdp')"
                            :title="$t('dashboard:gdp')"
                            :description="`${ $t('dashboard:gdp:description') }, `"
                            :graphs="buildGraphData([], ['gdp',])"
                            :apiData="this.graphData['gdp']"
                            :growthOverText="$t('dashboard:gdp:growth_over')"
                            displayUnit="%"
                        />
                        <dashboard-graph
                            v-if="checkShowGraph('unemployment_rate')"
                            :title="$t('dashboard:unemployment_rate')"
                            :description="`${ $t('dashboard:unemployment_rate:description') }, `"
                            :graphs="buildGraphData([], ['unemployment_rate',])"
                            :apiData="this.graphData['unemployment_rate']"
                            growthOverUnit="prevYear"
                            displayUnit="%"
                        />
                        <dashboard-graph
                            v-if="checkShowGraph('interest_rate')"
                            :title="$t('dashboard:interest_rate')"
                            :description="`${ $t('dashboard:interest_rate:description') }, `"
                            :graphs="buildGraphData([], ['interest_rate',])"
                            :apiData="this.graphData['interest_rate']"
                            growthOverUnit="prevYear"
                            displayUnit="%"
                        />
                        <dashboard-graph
                            v-if="checkShowGraph('housing_loan_rate')"
                            :title="$t('dashboard:housing_loan_rate')"
                            :description="`${ $t('dashboard:housing_loan_rate:description') }, `"
                            :graphs="buildGraphData([], ['housing_loan_rate',])"
                            :apiData="this.graphData['housing_loan_rate']"
                            growthOverUnit="prevYear"
                            :displayUnit="'mio\xa0€'"
                        />
                        <dashboard-graph
                            v-if="checkShowGraph('building_permissions')"
                            :title="$t('dashboard:building_permissions')"
                            :description="`${ $t('dashboard:building_permissions:description') }, `"
                            :graphs="buildGraphData([], ['building_permissions',])"
                            :apiData="this.graphData['building_permissions']"
                            growthOverUnit="prevYear"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="white-box dashboard-graph">
                            <news-feed
                                :enableMoreOnBottom="true"
                                :initialPerPage="9"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

import ApiService from '@/components/utils/api.service';
import ClusterableMap from '@/components/cool-clusterable-map/ClusterableMap.vue';
import DashboardGraph from './DashboardGraph.vue';
import NewsFeed from '../NewsFeed.vue';

import DashboardMixin from '@/../apps/analytics/vue/dashboard/DashboardMixin';

export default {
    components: {
        ClusterableMap,
        DashboardGraph,
        NewsFeed,
    },
    mixins: [ DashboardMixin ],
    data() {
        return {
            region: undefined,
            showLoading: true,
            transactions: [],
            marketComment: "",
            regionOptions: [],
            startingLevel: undefined,
            loadingTransactions: false,
        }
    },
    mounted() {
        this.getCurrentUser();
        this.getRegionLevels();
        this.getRegionOptions()
        .finally(() => {
            this.showLoading = false;
            this.getStaticAPIData();
            // These 2 are already called in region watch, don't need to call them here
            // this.getAPIData();
            // this.getAPIDataUnit();
        });
    },
    methods: {
        getRegionLevels(){
            ApiService
            .get("/market/analytics/api/region_levels")
            .then(response => {
                this.startingLevel = response.data;
            })
        },

        getRegionOptions() {
            return ApiService
            .get("/market/analytics/api/region_options")
            .then(response => {
                this.regionOptions = response.data;
                this.region = response.data[0];
                this.baseRegion = {
                    id: this.region.id,
                    name: this.$t(this.region.name)
                };
            })
        },
        getAPIData() {  // get data that changes with selected region
            ApiService
            .get("/market/analytics/api/graphs", {
                params: {
                    region: this.region.id,
                    sold_volume: 1,
                    med_selling_price_m2: 1,
                    sum_selling_price: 1,
                    arvio_ppi: 1,
                    advertisement_count: 1,
                    med_selling_price_m2_advertisement: 1,
                    adverts_per_household: 1,
                    type_listing: 1,
                }
            })
            .then(response => {
                if (this.baseGraphData === undefined) {
                    this.baseGraphData = response.data;                    
                }
                this.graphData = {...this.graphData, ...response.data};
            });
        },
        getStaticAPIData() {  // static data that is loaded on mount
            ApiService
            .get("/market/analytics/api/graphs", {
                params: {
                    region: this.region.id,
                    inflation: 1,
                    gdp: 1,
                    unemployment_rate: 1,
                    interest_rate: 1,
                    housing_loan_rate: 1,
                    building_permissions: 1,

                    sold_volume: 1,
                    med_selling_price_m2: 1,
                    sum_selling_price: 1,
                    arvio_ppi: 1,
                    advertisement_count: 1,
                    med_selling_price_m2_advertisement: 1,
                    adverts_per_household: 1,
                    type_listing: 1,
                }
            })
            .then(response => {
                if (this.baseGraphData === undefined) {
                    this.baseGraphData = response.data;                    
                }
                this.graphData = {...this.graphData, ...response.data};
            });
        },
        getAPIDataUnit() {
            if (this.getAPIDataUnitCancelTokenSource) {
                // Cancel previous region requests
                this.getAPIDataUnitCancelTokenSource.cancel("Canceling previous region requests!");
            }
            this.getAPIDataUnitCancelTokenSource = axios.CancelToken.source();
            
            this.loadingTransactions = true;
            ApiService
            .get("/market/analytics/api/unit_sales", {
                cancelToken: this.getAPIDataUnitCancelTokenSource.token,
                params: {
                    region: this.region.id
                }
            })
            .then(response => {
                this.transactions = response.data;
                this.loadingTransactions = false;
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Request canceled: ", error.message);
                } else {
                    throw error;
                }
            })
        },
        getAPIMarketComment() {
            this.marketComment = "";
            ApiService
            .get("/market/analytics/api/openai-market-comment", {
                params: {
                    region: this.region.id
                }
            })
            .then(response => {
                this.marketComment = response.data.trim();
            })
        },
        setRegion(region) {
            this.region = region;
        },
        isRegionCurrentlySelected(region) {
            return region.id == this.region.id;
        },
    },
    watch: {
        region() {
            this.getAPIData();
            this.getAPIDataUnit();
            this.getCurrentUser()
        },
        currentUser() {
            if (this.isOpenAIEnabled())
                this.getAPIMarketComment();
        }
    }
}
</script>
<style>
.dashboard-analytics-container .dashboard-graph {
    position: relative;
    border-radius: 2rem;
    height: 100%;
}
.dashboard-analytics-container .market-comment {
    white-space: pre-wrap;
}
.dashboard-analytics-container .transaction-table {
    border-radius: 2rem;
    width: 100%;
    min-height: 500px;
}
.dashboard-analytics-container .text-normal {
    font-weight: normal !important;
}
.dashboard-analytics-container .region-select-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.dashboard-analytics-container .region-select-container > div {
    flex: 1;
    border-radius: 16px;
    border-color: #60C8B6;
    border-width: 2px;
    border-style: solid;
    padding: 5px;
    text-align: center;
    cursor: pointer;
}
.dashboard-analytics-container .region-select-container > div.selected {
    background-color: #beede6;
}
@media (min-width: 768px) {
    .dashboard-analytics-container .static-widgets {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .dashboard-analytics-container .static-widgets > div {
        flex: 1 0 calc(33.33% - 50px);
        height: auto;
    }
}
</style>
