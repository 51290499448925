<template>
    <div class="vld-parent">
        <loading 
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div class="table-responsive">
                        
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>{{ $t('general:real_estate')}}</th>
                        <th>{{ $t('ee')}}</th>
                        <th>{{ $t('general:type')}} <help-icon slug="energetska-izkaznica-tip" /></th>
                        <th>{{ $t('ee:class')}}</th>
                        <th>{{ $t('ee:indicator')}} <help-icon slug="energetska-izkaznica-energijski-kazalniki" /></th>
                        <th>{{ $t('general:date')}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="session_energy_report_data.units && !session_energy_report_data.units.length">
                    <tr>
                        <td class="text-center" colspan="3">
                          {{ $t('ee:no_data')}}
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <template v-for="(prop, idx) in session_energy_report_data.units">
                        <property-energy-report-details
                            v-model="prop.energy_report"
                            :property_analysis="prop"
                            :key="'energy-report-tr'+idx"
                            @recreate="recreateEnergyReport(prop.id)"
                        />
                    </template>
                </tbody>
            </table>
            <div class="text-center">
                <span class="data-source-info">
                    {{ $t('ee:data:source:KN')}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import ApiService from "@/components/utils/api.service.js"
import HelpIcon from "@/components/widgets/HelpIcon";

import PropertyEnergyReportDetails from "./components/PropertyEnergyReportDetails.vue"

export default {
    components: {
        Loading,
        PropertyEnergyReportDetails,
        HelpIcon
    },
    props:  {
        session_id: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            session_energy_report_data: [],

            retries: 0,
        }
    },
    methods: {
        validateEnergyReportTaskStatus: function() {
            this.loading = true;
            ApiService
                .get(`/amas/analysis/analysis/api/${this.session_id}/task/status?name=energy_efficiency`)
                .then(response => {
                    if(response.data.status_code=="STARTED" && this.retries < 10) {
                        this.retries += 1;
                        setTimeout(this.validateEnergyReportTaskStatus, 1000);
                    } else {
                        this.getSessionEnergyReport();
                    }
                })
        },
        getSessionEnergyReport: function() {
            this.loading = true;
            ApiService
                .get(`/amas/analysis/api/analysis/${this.session_id}/energy-report`)
                .then(response => {
                    this.session_energy_report_data = response.data;
                })
                .catch(error => {
                    throw error;
                })
                .then(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
    },
    mounted() {
        this.validateEnergyReportTaskStatus();
    }
}
</script>