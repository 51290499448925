<template>
    <div>
        <k-wms-leaflet-map
            v-bind="$props"
            ref="map"
            :showDefaultOverlayLayers="false"
            @click="$emit('click', $event)"
            @control-layers-ready="handleControlLayersReady"
            :legend="mapLegend"
            :fullscreen="true"
        >
            <template v-slot:extra_controls>
                <l-control
                    v-if="layersLoading"
                    position="bottomright"
                >
                    <i class="fa fa-spinner fa-spin" style="font-size: 30px;"></i> 
                </l-control>
            </template>
        </k-wms-leaflet-map>
    </div>
</template>

<script>
import L from 'leaflet';
import * as esri from 'esri-leaflet';

import { LControl } from 'vue2-leaflet';

import KWMSLeafletMap from './KWMSLeafletMap';
import baseprops from './KSimpleLeafletMap-props'

import i18n from '@/i18n.js';

export default {
    setup() {
        return {
            i18n,
        }
    },
    components: {
        LControl,
        "k-wms-leaflet-map": KWMSLeafletMap,
    },
    props: {
        ...baseprops,
        arcGisLayers: {
            type: Array,
            required: false,
        }
    },
    data: function() {
        return {
            esriLayers: [],
            layersLoading: false,
        }
    },
    methods: {
        addDynamicMapLayer: function(data) {
            const app = this;

            let layer = esri.dynamicMapLayer(data);
            if ( data.visible ) {
                layer.addTo(this.leafletMapObject);
                if ( data.zIndex ) {
                    layer.setZIndex(data.zIndex);
                }
                // layer.bringToFront();
                // layer.bringToBack();
            }
            this.esriLayers.push(layer);
            layer.name = data.name;

            layer.on("loading", function (event) {
                app.layersLoading = true;
            });

            layer.on("load", function (event) {
                app.layersLoading = false;
            });
        },
        addArcGisLayers: function() {
            if (this.arcGisLayers) {
                this.arcGisLayers.forEach(l => {
                    this.addDynamicMapLayer(l);
                });
            }
        },
        handleControlLayersReady: function() {
            this.esriLayers.forEach(layer => {
                this.$refs.map.addLayerToControl(layer, layer.name);
            });
        }
    },
    computed: {
        leafletMapObject: function(){
            return this.$refs.map.leafletMapObject;
        },

        mapLegend: function() {
            // NOTE: This should be moved to implementation, it doesn't belong
            // to this general component!
            const app = this;
            let legend = L.control({position: "bottomleft"});
            legend.onAdd = function () {
                // Legend itself
                let legendDiv = L.DomUtil.create('div', 'm-t-10');
                legendDiv.innerHTML += `
                    <div class="arcis-map-legend-div">
                        <b>Območje veljavnosti rezultatov (VK) (4010)</b>
                        <table>
                        <tbody><tr valign="middle">
                        <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQElEQVQ4je3MsQkAMAhE0V84mJtJNstmSWPAXkl1H47rngGHwSx/AbtpORAP3AMgFRxLoECBAv+CPmB5BSPX7gLD5gbNFj3KKAAAAABJRU5ErkJggg==" alt="">
                        </td><td></td>
                        </tr>
                        </tbody></table>

                        <b>Območje razreda velike nevarnosti (VK) (4011)</b>
                        <table>
                        <tbody><tr valign="middle">
                        <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAwElEQVQ4ja3VMWoCQRSA4S/4SLnVNuoFApaexbuIVUrv4lnSWwmp0lhZqsvYuILszhLdmfLx8w0DDyYubILKwDnz9cl+qGmoE4cIqg/WufCPFX6m7HLNL4tgNaOKoVvvmP9gc74T2yz4KtbOesF3sV5wDNYBx2JPYAnsAZbCIBrqUlhDHaWw9pUx4VgKm3DMLvar2JRdYtkLvoO1sw44BuuAY7EnsAT2AEth3Be7FAaROMyoEtuhsP0GEstcc+V0A3Y7XRX6rwXjAAAAAElFTkSuQmCC" alt="">
                        </td><td></td>
                        </tr>
                        </tbody></table>

                        <b>Območje razreda srednje nevarnosti (VK) (4012)</b>
                        <table>
                        <tbody><tr valign="middle">
                        <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA0klEQVQ4ja3VsQ2CUBDG8f/hq6loiAuYWDILM6AbGCtLBrCAGZjF3orExtdYucBZICQEHgq8Ky9ffnfFJWeeJWdRQiZKYSdw/5GJEGojShgfOLmCtiQVuMUZlSvzuLI3G9IAQjM11ZakAP9g2yMXW5A7wblY2xsFl2Kj4BpsAK7FeqAPrAN9YQBGIRJPmEJkfG1mS1JVMAIvHxiAwMt52HOxOKOyBckouARrewNwDTYA12I90AfWgb4w+B62L6zZUKgDCG1BPhVUbd6ALUicGeH9ATjKhO8Fx/VmAAAAAElFTkSuQmCC" alt="">
                        </td><td></td>
                        </tr>
                        </tbody></table>

                        <b>Območje razreda majhne nevarnosti (VK) (4013)</b>
                        <table>
                        <tbody><tr valign="middle">
                        <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAsElEQVQ4ja3VsQnDMBCF4f9AtSoNEUjpWbxLSJXSu3iW9K48gasscGl8BmNZiay78nh84uCBgipPIFKeGzD9yCRgDkAU4XGWUqUH3iKMhcwd6IEYSk+uGP9gIrxUGU7BWsx2WfAqlgVbsAPYiu1AD2wDvTADkxcGpOCF2ZUBWLwwYCkVuwoTYVSlOyt2NWa7XLEvYwewFduBHtgGemEGJi/MwBmIqgylIOs3oEpXyHy+90564Rk/fxUAAAAASUVORK5CYII=" alt="">
                        </td><td></td>
                        </tr>
                        </tbody></table>
                        <b>Območje razreda preostale nevarnosti (VK) (4014)</b>
                        <table>
                        <tbody><tr valign="middle">
                        <td><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAW0lEQVQ4je2QwQnAMAzEZPAkGS4zdIRkSa/ifNqSPlu7P98nCIMIUocONHJmCjSBI8PmMHQDF5AIA/MW7sev/Phh1v4TVsO4sBrGhdUwLsxsaA4DmOd77TUDtgDHBEvLCwucCgAAAABJRU5ErkJggg==" alt="">
                        </td><td></td>
                        </tr>
                        </tbody></table>
                    </div>
                `
                legendDiv.toggleAttribute('hidden');
                
                app.legendDiv = legendDiv;

                // Toggle legend button
                let legendToggle = L.DomUtil.create('button', 'btn btn-xs')
                legendToggle.innerHTML += app.$t("maps:legend")
                L.DomEvent.addListener(legendToggle, 'click', function () {
                    app.legendDiv.toggleAttribute('hidden');
                });

                let div = L.DomUtil.create('div');
                div.appendChild(legendToggle);
                div.appendChild(legendDiv);

                return div;
            }
            return legend;
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.addArcGisLayers()
        })
    },
}
</script>

<style>
.arcis-map-legend-div {
    padding: 6px 8px;
    background: white;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
    line-height: 25px;
    color: #555;
    text-align: left;
    white-space: nowrap;
}
</style>
