<template>
    <div class="property-iq__comparable-transactions-tab">
        <!-- START Comparable transactions -->
        <div class="row">
            <div class="col-md-12">
                <h3>{{ $t('property_iq:tabs:similar_sales')}}</h3>

                <transactions-table
                    :transactions="indexedComparableTransactions(includeImages=true)"
                    :comparableTransactionItemsIds="comparableTransactionItemsIds"
                    :includeImages="true"
                    :highlightTransactionId="clickedMarkerData ? clickedMarkerData.id : null"
                    @comparable-clicked="$emit('comparable-clicked', $event)"
                />

                <div class="text-center">
                    <button class="btn btn-info text-center" v-on:click.prevent="$modal.show('comparable-transactions-search-modal')">
                    {{ $t('comparable:edit')}}
                    </button>
                </div>

                <bootstrap3-modal
                    :title="$t('comparable:search')"
                    name="comparable-transactions-search-modal"
                    width="80%"
                >   
                    <transactions-search
                        :comparableTransactionItemsIds="comparableTransactionItemsIds"
                        :mainMarker="mainMarker"
                        @comparable-clicked="$emit('comparable-clicked', $event)"
                    />
                </bootstrap3-modal>

                <div class="m-t-20" v-if="comparableTransactions">
                    <transactions-map
                        :height="'500px'"
                        :markers="indexedComparableTransactions().map(i => ({id: i.id, idx: i.idx, gps: i.gps}))"
                        :mainMarker="mainMarker"
                        :clickedMarkerData="this.clickedMarkerData"
                        :comparableTransactionItemsIds="comparableTransactionItemsIds"
                        :center="getMapCenter"
                        :maxClusterRadius="2"
                        @marker-clicked="getClickedMarkerData"
                        @comparable-clicked="$emit('comparable-clicked', $event)"
                        ref="map"
                    />
                </div>
            </div>
        </div>
        <!-- END Comparable transactions -->

        <hr class="m-t-40 m-b-20" />

        <!-- START Transaction history -->
        <div class="row">
            <div class="col-md-12">
                <h3>{{ $t('property_iq:tabs:transaction_history') }}</h3>
        
                <property-transaction-history
                    :re_key="re_key"
                ></property-transaction-history>
                
                <property-building-transaction-history
                    :re_key="re_key"
                ></property-building-transaction-history>
            </div>
        </div>
        <!-- END Transaction history -->
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"

import ComparableTransactionsTable from "./Table.vue";
import ComparableTransactionsSearch from "./search/Search.vue";
import PropertyIQTransactionMap from "./Map.vue"

import PropertyTransactionHistory from '@/components/property-transaction-history/PropertyTransactionHistory.vue'
import PropertyBuildingTransactionHistory from '@/components/property-transaction-history/PropertyBuildingTransactionHistory.vue'

import Bootstrap3Modal from '@/components/utils/Bootstrap3Modal.vue'

export default {
    name: "TransactionsTab",
    components: {
        "transactions-table": ComparableTransactionsTable,
        "transactions-search": ComparableTransactionsSearch,
        "transactions-map": PropertyIQTransactionMap,
        "bootstrap3-modal": Bootstrap3Modal,
        "property-transaction-history": PropertyTransactionHistory,
        "property-building-transaction-history": PropertyBuildingTransactionHistory,
    },
    props:  {
        re_key: {
            type: String,
            required: true
        },
        comparableTransactions: {
            type: Array,
            default: () => []
        },
        mainMarker: {
            type: Object
        }
    },
    data: function () {
        return {
            clickedMarkerData: null
        }
    },
    computed: {
        getMapCenter: function() {
            // let itemsWithGps = this.comparableTransactions.filter(i => i.sale_object.gps);
            // if(itemsWithGps && itemsWithGps.length){
            //     return [
            //         itemsWithGps[0].sale_object.gps.lat,
            //         itemsWithGps[0].sale_object.gps.lng
            //     ];
            // }
            if(this.mainMarker && this.mainMarker.coordinates) {
                return this.mainMarker.coordinates;
            }
            return undefined;
        },
        comparableTransactionItemsIds: function() {
            return this.indexedComparableTransactions().map(i => i.id);
        }
    },
    methods: {
        indexedComparableTransactions: function(includeImages) {
            return this.comparableTransactions.map((transaction, idx) => {
                let i = { ...{idx: idx + 1}, ...transaction, ...transaction.sale_object}
                if(includeImages) {
                    i.streetview_image = transaction.streetview_image;
                    i.map_image = transaction.map_image
                }
                return i
            })
        },
        getClickedMarkerData: function(clickedMarker) {
            this.clickedMarkerData = null;
            ApiService
                .get("/market/api/search", {
                    params: { id: clickedMarker.id }
                })
                .then(response => {
                    this.clickedMarkerData = response.data;
                    this.clickedMarkerData.idx = clickedMarker.idx;
                })
                .catch(error => {
                    throw error;
                })
        }
    }
}
</script>