<template>
    <div>
        <base-task-status
            :session_id="session.id"
            task_name="energy_efficiency"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            return ApiService
                .get(`/amas/analysis/api/analysis/${this.session.id}/energy-report`)
                .then(response => {
                    let icon;
                    if (response.data.successful) {
                        let url = `/amas/analysis/${this.session.id}/energy-list`;
                        icon = `
                        <a href="${url}">
                        <span class="circle circle-sm bg-success" data-toggle="tooltip" data-original-title="${this.$t("task_details:status:fetch:successful2")}">
                            <i class="fa fa-check"></i>
                        </span>
                        </a>
                        `
                    } else {
                        icon = `
                        <span class="circle circle-sm bg-light" data-toggle="tooltip" data-original-title="${this.$t("ee:no_data")}">
                            <i class="fa fa-close"></i>
                        </span>
                        `
                    }
                    return icon;
                })
        }
    },
}
</script>