<template>
    <tbody>
        <tr class="section-title-row">
          <td >
            {{$t("task_details:properties:title")}}
          </td>
          <td class="text-center"
              v-for="(session, session_idx) in subject.session_list"
              :key="'session-properties-td-'+session_idx">


                <button
                    class="btn btn-info btn-xs m-t-5"
                    @click.prevent="$emit('open-edit-realestates-modal', session)"
                    v-if="editable"
                >
                    {{$t("task_details:valuation:edit_subject")}}
                </button>
          </td>
        </tr>
        <tr>
            <td>

            </td>
            <td
                v-for="(session, session_idx) in subject.session_list"
                :key="'session-properties-td-'+session_idx"
                class="text-center"
            >

                <table style=" margin-left: auto; margin-right: auto" >
                  <tr class="property-itm"
                    v-for="(re, re_idx) in VSUtils.getSessionRealEstates(subject.real_estates, session)"
                    :key="'session-'+session_idx+'-property-'+re_idx"
                  >
                        <td class="text-right">
                            <re-open-modal
                                v-if="isNewCustomRealEstate(re)"
                                :re_key="re.re_key"
                            />
                            <span v-else>
                                {{ re.re_key }}
                            </span>
                        </td>
                        <td class="text-left">
                            <div style="display:flex">
                                <span v-if="re.real_estate">
                                    {{VSUtils.getRealEstateType(re).display}}, {{VSUtils.getRealEstateSize(re)|formatSize}}
                                </span>
                                <span style="margin-left:10px;">
                                    <unit-size-estimate-indicator
                                        v-if='re.real_estate && re.real_estate.unit'
                                        :unit="re.real_estate.unit"    
                                        :is_visible="!re.custom_size"
                                    />      
                                </span>       
                            </div>             
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </tbody>
</template>

<script>
import ReOpenModal from "@/components/widgets/ReOpenModal.vue"
import UnitSizeEstimateIndicator from "@/../apps/real_estate/vue/components/UnitSizeEstimateIndicator.vue"
import VSUtils from "../components/utils.js"

export default { 
    model: {
        prop: 'subject',
        event: 'change'
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        size_estimate_indicator: {
            type: Boolean,
            default: true
        } 
    },
    components: {
        ReOpenModal,
        UnitSizeEstimateIndicator
    },
    data () {
        return {
            VSUtils: VSUtils,
        }
    },
    methods: {
        isNewCustomRealEstate(re) {
            return re && re.id && !String(re.id).startsWith("new_custom");
        },
    }
}
</script>
