<template>
    <div id="virtual-visit-vue-app">

        <loading :active="loadingSessionData" :can-cancel="false" :is-full-page="false" />

        <div class="row">
            
            <div class="col-md-8 content-col" v-if="session">

                <div id="videos">
                    <div id="subscriber" v-if="!session.is_completed">
                        <div id="publisher" class="topcorner"></div>
                        <div class="vv-call--session-actions">
                            <a class="btn btn-default btn-xs" type="button" @click="sessionReload()">
                                Ponovno vzpostavi klic
                            </a>
                            <!-- Currently disabled! -->
                            <!-- <a class="btn btn-default btn-xs" type="button" @click="confirmCancelCall()">
                                Prekini klic
                            </a> -->
                        </div>

                        <img v-if="!forceHideCallIcon && !OTsubscribers.length && !OTconnectionFailed" :src="require('@/images/phone-call.gif')" />
                        <div v-else-if="OTconnectionErrorMessages.length" class="flex-centered">
                            <ul class="custom-unstyled-list">
                                <li v-for="(msg, idx) in OTconnectionErrorMessages" :key="'msg_'+idx+msg">{{ msg }}</li>
                            </ul>
                        </div>
                    </div>

                    <div id="subscriber" v-else class="flex-centered">
                        <h3>Klic je zaključen</h3>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <!--  START Steps whitebox  -->
                <div class="white-box" v-if="session">
                    <h2>Koraki</h2>
                    <b-card 
                        v-for="(step, stepNo) in steps"
                        :key="'step-tab-' + stepNo"
                        class="m-b-10 vv-call--step-container"
                    >
                        <b-card-header role="tab" :class="getStepBgClass(stepNo)">
                            <a @click="goToStep(stepNo)">
                                {{ step.title }}
                                <div class="pull-right" v-html="getStepIcon(stepNo)"></div>
                            </a>
                        </b-card-header>

                        <b-collapse :visible="stepNo == currentStepNo" accordion="steps-accordion" role="tabpanel">
                            <b-card-body class="vv-call--step-card--body">
                                <component
                                    v-if="stepNo == currentStepNo"
                                    :is="step.component"
                                    :session="session"
                                    @finished="handleStepFinish"
                                    @reloadSessionData="getSessionData"
                                >
                                </component>
                            </b-card-body>
                        </b-collapse>

                    </b-card>

                    <div v-if="!session.is_completed" class="vv-call--nav-buttons" style="min-height: 20px;">
                        <a
                            :class="[
                                'btn',
                                stepAvailable(currentStepNo - 1) ? 'btn-info' : 'btn-default disabled'
                            ]"
                            type="button"
                            @click="prevStep()"
                        >
                            Nazaj
                        </a>
                        <a
                            :class="[
                                'btn',
                                stepAvailable(currentStepNo + 1) ? 'btn-info' : 'btn-default'
                            ]" 
                            :disabled="!stepAvailable(currentStepNo + 1)"
                            type="button"
                            @click="nextStep()"
                            v-tooltip="getStepRequirementsText(currentStepNo)"
                        >
                            Naprej
                        </a>
                    </div>
                </div>
                <!-- END Steps whitebox -->
            </div>

        </div>

        <notifications group="OTwarnings" />
    </div>
</template>

<script>
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)

import { Swal } from '@/components/utils/swal.alerts.js';
import ApiService from '@/components/utils/api.service.js'

import Step0 from './steps/0_connection.vue'
import Step1 from './steps/1_documents.vue'
import Step2 from './steps/2_location.vue'
import Step3 from './steps/3_images.vue'
import Step4 from './steps/4_finish.vue'

const initialSteps = {
    0: {
        title: "1. Vzpostavljanje klica",
        status: null,
        component: Step0,
        name: "step0",
        path: "/step0",
        stepRequirementsText: "Povezava s stranko mora biti vzpostavljena",
    },
    1: {
        title: "2. Pridobi soglasje",
        status: null,
        component: Step1,
        name: "step1",
        path: "/step1",
        stepRequirementsText: "Podpisani morajo biti vsi obvezni dokumenti",
    },
    2: {
        title: "3. Pridobi lokacijo",
        status: null,
        component: Step2,
        name: "step2",
        path: "/step2",
        stepRequirementsText: "Lokacija mora biti zahtevana",
    },
    3: {
        title: "4. Naredi slike",
        status: null,
        component: Step3,
        name: "step3",
        path: "/step3",
        stepRequirementsText: "Klic mora biti zaključen",
    },
    4: {
        title: "5. Zaključek",
        status: null,
        component: Step4,
        name: "step4",
        path: "/step4",
    },
}


const store = new Vuex.Store({
    state: {
        OTsession: null,
        OTpublisher: null,
        OTsubscribers: [],
    },
    mutations: {
        setOTsession(state, val) {
            state.OTsession = val;
        },
        setOTpublisher(state, val) {
            state.OTpublisher = val;
        },
        addOTsubscriber(state, val) {
            state.OTsubscribers.push(val);
        },
        removeOTsubscriber(state, streamId) {
            state.OTsubscribers = state.OTsubscribers.filter(subscriber => {
                return subscriber.stream.streamId !== streamId;
            });
        },
    },
})


export default {
    store: store,
    props: {
        session_id: {
            type: Number,
            required: true
        },
        ot_api_key: {
            type: String,
            required: true
        },
    },
    data: function () {
        return {
            session: null,
            steps: initialSteps,
            loadingSessionData: false,
            currentStepNo: null,
            
            forceHideCallIcon: false,
            OTconnectionFailed: null,
            OTconnectionErrorMessages: [],
        };
    },
    computed: {
        OTsession() {
            return this.$store.state.OTsession;
        },
        OTpublisher() {
            return this.$store.state.OTpublisher;
        },
        OTsubscribers() {
            return this.$store.state.OTsubscribers;
        },
        currentStep() {
            return this.steps[this.currentStepNo];
        },
        clientUrl() {
            if (!this.session) { return ; }
            return this.session.client_url_full;
        },
    },
    methods: {
        getSessionData: function () {
            this.loadingSessionData = true;
            return ApiService
                .get(`/virtual-visit/api/virtual-session/${this.session_id}`)
                .then(response => {
                    this.session = response.data;
                    return response.data;
                })
                .catch(error => {
                    throw error;
                })
                .then(() => {
                    this.loadingSessionData = false;
                })
        },
        // ------------------------------
        // Steps
        // ------------------------------
        prevStep: function () {
            let currentStepNo = this.currentStepNo;
            let targetStepNo = currentStepNo - 1;
            this.goToStep(targetStepNo);
        },
        nextStep: function () {
            let currentStepNo = this.currentStepNo;
            let targetStepNo = currentStepNo + 1;
            this.goToStep(targetStepNo);
        },
        goToStep: function (stepNo) {
            if (this.stepAvailable(stepNo)) {
                this.currentStepNo = parseInt(stepNo);
                if (this.steps[stepNo].status == null) {
                    this.startStep(stepNo);
                }
            }
        },
        stepAvailable: function (stepNo) {
            let step = this.steps[stepNo];
            if (step && (step.status == "finished" || step.status == "in_progress" || step.status == "skipped")) {
                return true;
            }

            let prevStep = this.steps[stepNo - 1];
            if (!prevStep) { return false; }
            return prevStep.status == "finished";
        },
        startStep: function (step_no) {
            this.steps[step_no].status = "in_progress";
        },
        finishStep: function (step) {
            this.steps[step].status = "finished";
        },
        failStep: function (step) {
            this.steps[step].status = "failed";
        },
        skipStep: function (step) {
            this.steps[step].status = "skipped";
        },
        handleStepFinish: function (step, goToNext = false) {
            this.finishStep(step);
            if (goToNext) {
                this.$nextTick(() => {
                    // this.notify("Korak zaključen", "", "success");
                    this.goToStep(step + 1);
                });
            }
        },
        getStepIcon: function (stepNo) {
            let status = this.steps[stepNo].status;
            if (status === "finished") {
                return "<i class='fa fa-check'></i>";
            } else if (status === "skipped") {
                return "<i class='fa fa-arrow-right'></i>";
            } else if (status === "in_progress") {
                return "<i class='fa fa-spinner fa-spin'></i>";
            } else if (status === "failed") {
                return "<i class='fa fa-exclamation'></i>";
            } else {
                return "";
            }
        },
        getStepBgClass: function (stepNo) {
            let status = this.steps[stepNo].status;
            let alertClass = "";
            if (status === "failed") {
                alertClass += "bg-danger";
            } else if (status === "finished") {
                alertClass += "bg-success";
            } else if (status === "in_progress") {
                alertClass += "bg-info";
            } else {
                alertClass += "bg-grey";
            }
            return alertClass;
        },
        getStepRequirementsText: function (stepNo) {
            let step = this.steps[stepNo];
            if (step && step.status != "finished") { return step.stepRequirementsText }
            return null;
        },

        // ------------------------------
        // OT session setup
        // ------------------------------
        initOTSession: function () {
            const app = this;
            let OTsession = app.$OT.initSession(app.ot_api_key, app.session.session_id);
            app.$store.commit("setOTsession", OTsession);
            app.initOTPublisher();

            app.OTsession.on("streamCreated", (event) => {
                app.initOTSubscriber(event);
            });
            app.OTsession.on("streamDestroyed", (event) => {
                app.$store.commit("removeOTsubscriber", event.stream.streamId);
                app.notify("Stranka je prekinila povezavo", "", "warn");
                app.forceHideCallIcon = false;
            });
            app.OTsession.on("signal", function (event) {
                if (event.type == "signal:location") {
                    // Kept for possible future use
                } else if (event.type == "signal:screenshot") {
                    // Kept for possible future use
                } else if (event.type == "signal:report-submit") {
                    app.notify("Dokument prejet", "", "success");
                } else if (event.type == "signal:imageUrl") {
                    app.notify("Slikanje uspešno", "", "success");
                    app.session.images.push({ image: event.data.url, pk: event.data.pk });
                }
            })
        },
        initOTPublisher: function () {
            const app = this;
            let OTpublisher = app.$OT.initPublisher(
                "publisher",
                {
                    width: "150px",
                    height: "150px",
                    insertMode: "append",
                    fitMode: "contain",
                }
            );
            app.$store.commit("setOTpublisher", OTpublisher);
            app.OTsession.connect(app.session.token_moderator, (err) => {
                if (err) {
                    app.handleOTError(err);
                    app.failStep(0);
                    app.OTconnectionFailed = true;
                    throw err;
                }
                app.OTsession.publish(app.OTpublisher, (err) => {
                    if (err) {
                        app.handleOTError(err);
                        app.failStep(0);
                        app.OTconnectionFailed = true;
                        throw err;
                    }
                    this.notify("Povezani ste na sejo", "", "success");
                });
            });
        },
        initOTSubscriber: function (event) {
            const app = this;
            if (event.type === "streamCreated") {
                // If event that client has joined stream, send to server to create statistics
                ApiService.get(`/virtual-visit/client_join/${this.session.session_id}`);
            }

            app.forceHideCallIcon = true;
            let subscriberOptions = {
                width: "100%",
                height: "100%",
                insertMode: "append",
                fitMode: "contain"
            };
            let OTsubscriber = app.OTsession.subscribe(event.stream, "subscriber", subscriberOptions, (err) => {
                if (err) {
                    app.handleOTError(err);
                    throw err;
                }
                app.handleStepFinish(0, true);
                app.$store.commit("addOTsubscriber", OTsubscriber);
                this.notify("Stranka se je povezala na sejo", "", "success");
            });
        },
        sessionReload: function(){
            if (this.OTsession) {
                this.OTsession.signal({
                    type: "textMessage",
                    data: "sessionReload"
                }, (error) => {
                    if (!error) { location.reload(); }
                });
            } else {
                location.reload();
            }
        },
        confirmCancelCall: function(){
            const app = this;
            Swal({
                title: "Ali ste prepričani?",
                text: "Ali ste prepričani, da želite prekiniti klic?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Da, prekini klic!",
                cancelButtonText: "Ne, prekliči",
            }).then(async function(val){
                if (val.isConfirmed) {
                    app.cancelCall();
                }
            });
        },
        cancelCall: function(){
            const app = this;
            if ( app.OTsession ) {
                if (app.OTsession.connection) {
                    app.OTsession.signal({
                        type: "disconnect",
                        data: "disconnect"
                    }, (error) => {
                        if (error) {
                            // app.$parent.handleOTError(error);
                        }
                    });
                }
                app.OTsession.disconnect();
                app.$store.commit("setOTsession", null);
                window.location.href = '/virtual-visit/list';
            }
        },
        handleOTError: function (err) {
            let msg = "";
            if (err.code === 1004) {
                msg = "Seja je potekla";
                this.OTconnectionErrorMessages.push(msg);
            }
            this.notify("Napaka pri povezavi", msg, "warn");
        },

        // ------------------------------
        // Utils
        // ------------------------------
        notify: function (title = "", text = "", type = "info") {
            this.$notify({
                group: "OTwarnings",
                type: type,
                title: title,
                text: text
            });
        },
    },
    mounted: function () {
        const app = this;
        this.getSessionData().then(() => {
            if (app.session.is_completed) {
                this.skipStep(0);
                this.skipStep(1);
                this.skipStep(2);
                this.skipStep(3);
                this.startStep(4);
                this.goToStep(4);
            } else {
                this.startStep(0);
                this.goToStep(0);
                this.initOTSession();
            }
        });
    },
};
</script>

<style scoped>
#publisher {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 1;
}

#subscriber {
    position: relative;
    width: 100%;
    height: 40em;
    background: white;
    text-align: center;
}

#subscriber img {
    height: 100%;
}

.vv-call--session-actions {
    position: absolute;
    bottom: 5px;
    right: 0px;
    z-index: 1;
}
.vv-call--session-actions a {
    border-radius: 2px;
    margin-right: 5px;
}
.vv-call--nav-buttons {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0.8); /* White background with 80% opacity */
    
}
.flex-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.vv-call--step-container {
    border: 1px solid rgb(230, 230, 230);
    overflow: hidden;
    border-radius: 0.60em;
}
.vv-call--step-container .card-header a {
    display: block;
    padding: 10px !important;
    color: #fff;
    cursor: pointer;
}
.card-header.bg-grey a {
    color: black;
}
.vv-call--step-card--body {
    padding: 10px;
}
</style>