<template>
    <tr>
        <td v-if="columns.includes('transaction_id')">
            <div v-if="!hide_column_values.includes('transaction_id')" class="flexo">
                <span data-toggle="tooltip" data-original-title="Podrobnosti o transakciji" rel="tooltip">
                    <a :href="'/market/transaction/'+item.transaction_id+'/details'" class="open-modal">
                        #{{item.transaction_id}}
                    </a>
                </span>
                <span v-if="item.is_cleaned"
                    data-original-title="Posel s prečiščenimi podatki"
                    data-toggle="tooltip" class="cursor-help">
                        <i class="fa fa-file-powerpoint-o"></i>
                </span>
                <span v-if="item.has_cleaned_images"
                    data-original-title="Posel s fotografijami"
                    data-toggle="tooltip" class="cursor-help">
                        <i class="fa fa-image"></i>
                </span>
                <span v-if="item.transaction_volume > 1" class="label label-rouded label-info" data-toggle="tooltip" 
                    data-original-title="Posel vsebuje več nepremičnin"
                    v-on:click.prevent="$emit('labelclicked', item.transaction_id)">
                        {{item.transaction_volume}}
                        <i class="fa"
                            v-bind:class="row_expanded ? 'fa-caret-up' : 'fa-caret-down'">
                        </i>
                </span>
            </div>
        </td>

        <td v-if="columns.includes('component_type')" style="max-width:200px;">
            {{item.get_component_type_display}}
        </td>

        <td v-if="columns.includes('re_key')">
            <span class="re-key" data-toggle="tooltip" data-original-title="Podrobnosti o nepremičnini" rel="tooltip">
                <a class="open-modal"
                    :href="'/amas/real_estate/'+item.re_key+'/details/1/market'">
                    {{item.re_key}}
                </a>
            </span>
        </td>

        <td v-if="columns.includes('address')">
            <span v-if="item.is_unit">
                {{item.address || '/'}}
            </span>
            <span v-else>
                <span data-original-title="Katastrska občina" data-toggle="tooltip" class="cursor-help">
                    {{item.cadastral_municipality|capitalize}},
                </span>
                <span data-original-title="Občina" data-toggle="tooltip" class="cursor-help">
                    {{item.municipality|capitalize}}
                </span>
            </span>
        </td>

        <td v-if="columns.includes('transaction_date')">
            {{item.transaction_date}}
        </td>

        <td v-if="columns.includes('transaction_amount_gross')">
            <div class="text-info font-medium" style="font-weight: 400">
                <span data-original-title="Vrednost posla / pogodbena cena" data-toggle="tooltip" class="cursor-help">
                    {{item.transaction_amount_gross|formatCurrency(0)}}
                </span>
            </div>
            <div v-if="item.transaction_tax || item.component_tax">
                <small>z DDV</small>
            </div>
        </td>

        <td v-if="columns.includes('transaction_amount_m2')">
            <span v-if="item.transaction_amount_m2">
                <span class="font-medium text-primary">
                    {{item.transaction_amount_m2|formatCurrencym2(0)}}
                </span>
                <div v-if="item.transaction_tax || item.component_tax">
                    <small>z DDV</small>
                </div>
            </span>
            <span v-else-if="item.estimated_amount_m2" class="font-medium text-secondary">
                {{item.estimated_amount_m2|formatCurrencym2(0)}}
            </span>
            <span v-else class="font-medium text-secondary">
                /
            </span>
        </td>

        <td v-if="columns.includes('building_year_built')">
            {{item.building_year_built || '/'}}
        </td>

        <td v-if="columns.includes('component_size')">
            <div style="font-weight: 400">
                <span>{{item.component_size ||'/'|formatSize}}
                    <span v-if="item.sale_share && item.sale_share != '1/1' && item.sale_share != '1'"
                        class="label label-rouded label-info" data-toggle="tooltip"
                        data-original-title="Delež prodaje">
                            {{item.sale_share}}
                    </span>
                </span>
            </div>
            <div style="font-size:smaller">
                <span data-original-title="Skupna površina zemljišč v poslu" data-toggle="tooltip" class="cursor-help">
                    Z:  {{item.transaction_sum_parcel_sizes||'/'|formatSize}}
                </span>
                |
                <span data-original-title="Skupna površina delov stavb v poslu" data-toggle="tooltip" class="cursor-help">
                    DS: {{item.transaction_sum_unit_sizes||'/'|formatSize}}
                </span>
            </div>
        </td>

        <td v-if="columns.includes('sizes')">
            <div style="font-weight: 400">
                <span data-original-title="Razmerje med uporabno in neto površino" data-toggle="tooltip"  class="cursor-help">
                    {{item.useful_net_size_ratio||'/'|formatNumber}}
                </span>
            </div>
            <div style="font-size:smaller">
                <span data-original-title="Netotlorisna površina" data-toggle="tooltip" class="cursor-help">
                    N: {{item.REN_net_size||'/'|formatSize}}
                </span>
                |
                <span data-original-title="Uporabna površina" data-toggle="tooltip" class="cursor-help">
                    U: {{item.REN_useful_size||'/'|formatSize}}
                </span>
            </div>
        </td> 

        <td v-if="columns.includes('score')"></td>

        <td v-if="columns.includes('wide_set_comparable_options')" class="text-center">
            <slot name="wide-set-options-cell"></slot>
        </td>

        <td v-if="columns.includes('narrow_set_comparable_options')" class="text-center">
            <slot name="narrow-set-options-cell"></slot>
        </td>

        <td v-if="columns.includes('basket_options')"></td>
    </tr>
</template>

<script>
export default {
    props:  {
        item: Object,
        columns: {
            type: Array,
            default: () => []
        },
        hide_column_values: {
            type: Array,
            default: () => []
        },
        row_expanded: {
            type: Boolean,
            default: false
        },
    },
}
</script>
