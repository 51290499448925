<template>
    <div>
        <div v-if="editing" v-on-clickaway="disableEditing">

            <div class="input-group input-group-sm">
                <slot>
                    <input
                        v-bind:value="value"
                        v-on:input="$emit('input', $event.target.value)"
                        v-on:keyup.enter="disableEditing"
                        class="form-control" 
                    >
                </slot>
                <span class="input-group-addon custom-cursor-pointer" @click="disableEditing">
                    <i class="fa fa-check"></i>
                </span>
            </div>
            
            <div v-if="error" class="text-danger">
                {{error}}
            </div>
        </div>
        <div v-else>
            <span @click="enableEditing" class="k-editable-field">{{computedDisplayValue}}</span>
            <a
                v-if="resetButton"
                v-tooltip="'Ponastavi'"
                v-on:click="$emit('reset')"
                class="m-l-5"
            >
                <i class="fa fa-refresh"></i>
            </a>
        </div>
    </div>
</template>

<script>

import {directive as onClickaway} from 'vue-clickaway';

export default {
    name: 'KEditable',
    props: {
        value: [String, Boolean, Number],
        defaultIfNone: [String, Boolean, Number],
        displayValue: [String, Boolean, Number],
        resetButton: {
            type: Boolean,
            default: false
        },
        validator: {
            type: Function,
            default: (value) => true
        }
    },
    directives: {
        onClickaway: onClickaway,
    },
    data: function() {
        return {
            editing: false,
            error: null
        }
    },
    methods: {
        enableEditing: function(){
            this.editing = true;
        },
        disableEditing: function(){
            if(!this.validate()) { return }
            this.error = null;
            this.$emit('close', this.value);
            this.editing = false;
        },
        validate: function(){
            let valid = this.validator(this.value);
            this.error = "Neveljavna vrednost";
            return valid;
        }
    },
    computed: {
        computedDisplayValue: function(){
            if(this.displayValue) { return this.displayValue; }
            else { return this.value ? this.value : this.defaultIfNone; }
        }
    }
}
</script>

<style scoped>
    .k-editable-field {
        border-bottom: dashed 1px #0088cc;
        cursor: pointer;
        font-style: italic;
    }
</style>