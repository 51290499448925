<template>
    <div class="container-fluid vld-parent">
        <loading 
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div class="row">
            <FormulateForm
                v-model="formValues"
                :keep-model-data="true"
            >
                <BaseOrderForm
                    :formValues="formValues"
                    :isReadOnly="isReadOnly"
                    :showAppraiserSelection="showAppraiserSelection"
                    :showQuoteDueDate="showQuoteDueDate"
                    @uploaded-order-file="handleOrderFileUpload"
                    @import-order-file="parseOrderFile"
                >
                    <div
                        v-if="order && showOffers && offers &&
                            checkOrderStatus(order, statuses.REQUEST_FOR_QUOTE, statuses.ACCEPTED)"
                        class="col-sm-8"
                    >
                        <FormPanelSection
                            title="Ponudbe in izvajalec"
                        >
                            <OrderOfferList
                                :order="order"
                                :offers="offers"
                                :showMoveToDepositoryButton="this.force_buyer_permissions && checkOrderStatus(order, statuses.ACCEPTED)"
                                :force_buyer_permissions="force_buyer_permissions"
                                :columns="customOrderOfferListColumns"
                                :loading="loading_offers"
                                @offer-selected="selectOffer"
                                @move-depository="$emit('move-depository')"
                                @refresh-offers="getOffers"
                            />
                        </FormPanelSection>
                    </div>

                    <div v-if="showAppraisers" class="col-sm-8">
                        <FormPanelSection
                            title="Cenilec in ponudba"
                        >
                            <!-- Selected appraiser display -->
                            <template v-if="isReadOnly && order && order.appraiser">
                                <h3 class="box-title">Cenilec</h3><hr>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="input-group">
                                            <div class="input-group-addon">
                                                <i class="ti ti-user"></i>
                                            </div>
                                            <input
                                                class="form-control"
                                                :value="`${order.appraiser.first_name} ${order.appraiser.last_name}`"
                                                :disabled="true"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-group">
                                            <div class="input-group-addon">
                                                <i class="ti ti-email"></i>
                                            </div>
                                            <input
                                                class="form-control"
                                                :value="order.appraiser.email"
                                                :disabled="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <!-- Select appraiser input -->
                            <template v-else>
                                <h3 class="box-title">Izbira cenilca</h3><hr>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <FormulateInput
                                            name="appraiser_mock"
                                            type="select"
                                            :options="{1: 'Boštjan Ocenjevalec', 2: 'Janez Cenilec', 3: 'Ajda Cenilka'}"
                                        />
                                    </div>
                                </div>
                            </template>

                            <!-- Spacing if both sections are visible -->
                            <div v-if="order && order.appraiser && order.selected_offer" style="height: 50px;"></div>

                            <!-- Offer -->
                            <template v-if="order && order.selected_offer">
                                <h3 class="box-title">Ponudba</h3>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <OrderOfferList
                                            :order="order"
                                            :offers="offers"
                                            :force_buyer_permissions="true"
                                            :columns="showAppraisersColumns"
                                            :showOnlySelected="true"
                                            :colorRows="false"
                                        />
                                    </div>
                                </div>
                            </template>
                        </FormPanelSection>
                    </div>
                    
                </BaseOrderForm>
            </FormulateForm>
        </div>

        <!--
            START OrderItems list / table
        -->
        <div class="row" v-if="showOrderItemsList">
            <div class="col-sm-12">
                <FormPanelSection
                    title="Cenitve"
                >
                    <BaseOrderItemTable
                        :items="orderItems"
                        :loading="loading_items"
                        :columns="customOrderItemsTableColumns"
                    >
                        <template v-slot:actions="{ item }">
                            <button
                                class="btn btn-sm btn-outline btn-info"
                                @click="openOrderItemDetailsModal(item.id)"
                            >
                                Odpri
                            </button>
                        </template>
                    </BaseOrderItemTable>
                </FormPanelSection>
            </div>
        </div>

        <bootstrap3-modal
            :title="'Podrobnosti cenitve'"
            name="orderItemDetailsModal"
            width="90%"
        >
            <OrderItem
                v-if="selected_order_item_id"
                :orderItemId="selected_order_item_id"
                :readOnly="orderItemDetails_readOnly || checkOrderStatus(order, statuses.CANCELED, statuses.COMPLETED)"
                :actionsEnabled="orderItemDetails_actionsEnabled"
                :amasAnalysisEnabled="orderItemDetails_amasAnalysisEnabled"
                @order-item-saved="getOrderItems"
                @order-item-action-change="orderItemActionChange"
            />
        </bootstrap3-modal>

        <!--
            START OrderItems list / table
        -->
        
        <!--
            START Submit buttons
        -->
        <div class="row" v-if="!isReadOnly">
            <div class="col-sm-6" v-if="mode=='procurement'">
                <FormulateForm
                    v-model="formValues"
                    @submit="saveOrder"
                >
                    <FormulateInput 
                        type="submit" label="Shrani osnutek"
                    />
                </FormulateForm>
            </div>

            <div class="col-sm-6" v-if="mode=='procurement'">
                <FormulateForm
                    v-model="formValues"
                    @submit="handleSaveOrder"
                >
                    <FormulateInput v-if="!orderId"
                        type="submit" label="Shrani in oddaj povpraševanje"
                    />
                    <FormulateInput v-else
                        type="submit" label="Posodobi in oddaj povpraševanje"
                    />
                </FormulateForm>
            </div>

            <div class="col-sm-12" v-if="mode=='depository'">
                <FormulateForm
                    v-model="formValues"
                    @submit="saveAndSubmitAcceptedOrder"
                >
                    <FormulateInput
                        type="submit" label="Shrani in oddaj naročilo" 
                    />
                </FormulateForm>
            </div>
        </div>
        <!--
            END Submit buttons
        -->

        <!--
            START Submit Offer Actions (for appraisers/supplier)
        -->
        <div
            v-if="order && showSubmitOfferOptions && !checkOrderStatus(order, statuses.CANCELED, statuses.COMPLETED)"
            class="row"
        >
            <OrderOffer
                :orderId="order.id"
                @offer-submitted="getOrder"
            />
        </div>
        <!--
            END Submit Offer Actions (for appraisers/supplier)
        -->

    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js";
import { Swal } from "@/components/utils/swal.alerts.js";
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";

import optionsmixin from "./mixins/optionsmixin.js";
import orderstatusmixin from "./mixins/orderstatusmixin.js";

import BaseOrderForm from "./BaseOrderForm.vue";
import OrderOfferList from "./OrderOfferList.vue";
import OrderOffer from "./OrderOffer.vue";
import BaseOrderItemTable from "./BaseOrderItemTable.vue";
import BaseInput from "./inputs/BaseInput.vue";
import FormPanelSection from "./FormPanelSection.vue";
import OrderItem from "./OrderItem.vue";

export default {
    components: {
        BaseOrderForm,
        OrderOffer,
        OrderOfferList,
        FormPanelSection,
        BaseOrderItemTable,
        OrderItem,
        BaseInput,
        "bootstrap3-modal": Bootstrap3Modal,
    },
    mixins: [ optionsmixin, orderstatusmixin ],
    props: {
        orderId: {
            type: [String, Number],
            required: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        showOffers: {
            type: Boolean,
            default: false,
        },
        showAppraisers: {
            type: Boolean,
            default: false,
        },
        showSubmitOfferOptions: {
            type: Boolean,
            default: false,
        },
        showOrderItemsList: {
            type: Boolean,
            default: false,
        },
        customOrderItemsTableColumns: {
            type: Array,
            default: () => [],
        },
        showAppraiserSelection: {
            type: Boolean,
            default: true,
        },
        showQuoteDueDate: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: "procurement",
            validator: value => {
                return ["procurement", "depository"].includes(value)
            },
        },
        orderItemDetails_readOnly: {
            type: Boolean,
            default: false,
        },
        orderItemDetails_actionsEnabled: {
            type: Boolean,
            default: false,
        },
        orderItemDetails_amasAnalysisEnabled: {
            type: Boolean,
            default: true,
        },
        // TODO: temporary; will be removed when we handle offer-list with permissions
        force_buyer_permissions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            order: null,
            orderItems: null,
            formValues: {
                "client_title": "",
                "client_email": "",
                "created_at": new Date().toISOString().substring(0,10),
                "appraiser_mock" : 1,
                "orderitem_set": [{
                    "orderitemproperty_set": [{
                        "re_key": null,
                        "real_estate_type": 1,
                        "address": null,
                        "country": null,
                    }]
                }]
            },
            offers: [],

            loading: false,
            loading_items: false,
            loading_offers: false,

            selected_order_item_id: null,
        }
    },
    computed: {
        isReadOnly: function() {
            if (this.order) {
                // force read-only
                if (this.checkOrderStatus(this.order, this.statuses.CANCELED, this.statuses.COMPLETED))
                    return true;
                // force not read-only
                if (this.checkOrderStatus(this.order, this.statuses.DRAFT))
                    return false;
            }
            return this.readOnly;
        },
        showAppraisersColumns: function() {
            if (this.order && this.showAppraisers)
                return [
                    {
                        field: 'created_at',
                        hidden: true,
                    },
                    {
                        field: 'created_by',
                        sortable: false,
                    },
                    {
                        field: 'due_date',
                        hidden: true,
                    },
                    {
                        field: 'offer_status',
                        hidden: true,
                    },
                    {
                        field: 'price',
                        sortable: false,
                    },
                    {
                        field: 'id',
                        hidden: true,
                    },
                    {
                        field: 'actions',
                        hidden: true,
                    },
                ]
            return [];
        },
        customOrderOfferListColumns() {
            return !this.force_buyer_permissions ? [
                {
                    field: "id",
                    label: "Izberi",
                    slot_name: "select",
                    sortable: false,
                    hidden: !this.order.selected_offer,
                },
                {
                    field: "actions",
                    label: "Akcija",
                    sortable: false,
                    slot_name: "actions",
                    hidden: this.order.selected_offer,
                },
            ] : [
                {
                    field: 'actions',
                    hidden: true,
                }
            ]
        }
    },
    methods: {
        parseOrder: function(data){
            const objCopy = {...data};
            return objCopy;
        },
        getOrder: function() {
            this.loading = true;
            return ApiService
                .get(`/streamline/api/order/${this.orderId}/`)
                .then(response => {
                    this.formValues = this.parseOrder(response.data);
                    this.order = response.data;
                    if ( this.showOrderItemsList ) {
                        this.getOrderItems();
                    }
                    this.getOffers();
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju naročila",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        getOrderItems: function(){
            this.loading_items = true;
            return ApiService
                .get("/streamline/api/order-item/list", {
                    params: {
                        // "process": "depository",
                        "order_id": this.order.id,
                    },
                })
                .then(response => {
                    this.orderItems = response.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju cenitev",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading_items = false;
                })
        },
        orderItemActionChange: function() {
            this.getOrderItems();
            this.$emit("order-submitted", this.order);
        },
        handleSaveOrder: function(data) {
            /**
            * if the orders were submitted, ask if you want to cancel the offers and then submit the order
            **/
            if (this.offers.length > 0) {
                Swal({
                    title: "Posodabljanje povpraševanja",
                    text: "Če posodobite povpraševanje bodo vse ponudbe preklicane. Želite nadaljevati?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Nadaljuj",
                    cancelButtonText: "Prekliči",
                })
                .then(async (val) => {
                    if (val.isConfirmed) {
                        await this.cancelOffers();
                        this.saveAndSubmitOrder(data);
                    }
                })
            } else {
                this.saveAndSubmitOrder(data);
            }
        },
        cancelOffers: function() {
            if (!this.orderId)
                return;
            this.loading = true;
            return ApiService
                .post(`/streamline/api/order/${this.orderId}/deprecate-offers/`)
                .then(() => {
                    // set all offers from accepted to order changed
                    this.offers.map(offer => {
                        if (offer.offer_status === 1)
                            offer.offer_status = 3;
                        return offer;
                    })
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri preklicu ponudb",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte ",
                        icon: "error",
                    })
                    throw error;    
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        saveAndSubmitOrder: function(data) {
            /**
            * This function saves the order (and creates it if required) and then submits it.
            **/
            this
                .createOrUpdateOrder(data)
                .then(_order => {
                    this.submitOrderAction("start-quote", {}, "Povpraševanje uspešno shranjeno in poslano")
                        .then(order => {
                            this.$emit("order-submitted", order);
                        })
                });
        },
        saveAndSubmitAcceptedOrder: function(data) {
            /**
            * This function saves the order (and creates it if required) and then submits it directly to the 
            * selected appraiser. Skips the quote phase.
            **/
            this
                .createOrUpdateOrder(data)
                .then(_order => {
                    this.submitOrderAction("select-appraiser", { appraiser_id: 1 }, "Cenilec izbran")
                        .then(order => {
                            this.$emit("order-submitted", order);
                        })
                });
        },
        saveOrder: function(data) {
            /**
            * This function saves the order (and creates it if required) without submiting it.
            **/
            this
                .createOrUpdateOrder(data)
                .then(order => {
                    Swal({
                        title: "",
                        text: "Osnutek povpraševanja uspešno shranjen",
                        icon: "success",
                    });
                    this.$emit("order-saved", order);
                });
        },
        validateOrderData: function(data) {
            const errors = [];
            // check that the real_estate_type is set for all order items
            if (data.orderitem_set) {
                data.orderitem_set.forEach(orderitem => {
                    orderitem.orderitemproperty_set.forEach(orderitemproperty => {
                        if (!orderitemproperty.real_estate_type) {
                            const msg = "Vnesite vrsto nepremičnine za vse predmete vrednotenja"
                            if (!errors.includes(msg)) {
                                errors.push(msg);
                            }
                        }
                    })
                })
            }
            return [errors.length === 0, errors];
        },
        createOrUpdateOrder: function(data) {
            // validate data
            const [valid, errors] = this.validateOrderData(data);
            if (!valid) {
                Swal({
                    title: "Napaka pri shranjevanju naročila",
                    text: `${errors.join(", ")}`,
                    icon: "error",
                }).then(() => {
                    this.loading = false;
                })
                return Promise.reject("Validation error");
            }

            // create or update order
            let createOrUpdateOrder = this.order ? this._updateOrder : this._createOrder;
            return createOrUpdateOrder(data);
        },
        _createOrder: function(data) {
            this.loading = true;
            return ApiService
                .post("/streamline/api/order/list", data)
                .then((response) => {
                    this.order = response.data;
                    return response.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri ustvarjanju naročila",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        _updateOrder: function(data) {
            this.loading = true;
            return ApiService
                .put(`/streamline/api/order/${this.order.id}/`, data)
                .then((response) => {
                    this.order = response.data;
                    return response.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri spreminjanju naročila",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        submitOrderAction: function( actionName, postData, successMessage ){
            return ApiService
                .post(`/streamline/api/order/${this.order.id}/${actionName}/`, postData)
                .then(response => {
                    Swal({
                        title: "",
                        text: successMessage,
                        icon: "success",
                    });
                    this.order = response.data;
                    return response.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri spreminjanju naročila",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
        },
        //
        // OrderOffers
        //
        selectOffer: function(offer_id) {
            this.loading_offers = true;
            this.submitOrderAction("select-offer", { offer_id: offer_id })
                .then(response => {
                    Swal({
                        title: "",
                        text: "Izvajalec izbran",
                        icon: "success",
                    });
                    this.getOffers().then(() => {
                        this.loading_offers = false;
                    })
                })
        },
        getOffers: function() {
            if (!this.order || this.checkOrderStatus(this.order, this.statuses.DRAFT)) return;

            this.loading_offers = true;
            return ApiService
                .get(`/streamline/api/order-offer/`, {
                    params: {
                        order_id: this.order.id,
                        force_buyer_permissions: this.force_buyer_permissions,
                    }
                })
                .then(response => {
                    this.offers = response.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju ponudb",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading_offers = false;
                })
        },
        handleOrderFileUpload: function(file) {
            // creates order and uploads file
            this.createOrUpdateOrder(this.formValues)
                .then(() => {
                    this.uploadOrderFile(file);
                })
        },
        uploadOrderFile: function(file) {
            const formData = new FormData();
            formData.append("order_file", file);
            this.loading = true;
            ApiService
                .post(`/streamline/api/order/${this.order.id}/order-file/`, formData)
                .then(res => {
                    this.formValues = this.parseOrder(res.data);
                    this.order = res.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri nalaganju naročilnice",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        parseOrderFile: function() {
            Swal({
                title: "",
                text: "Uvoz bo izbrisal trenuten predmet vrednotenja. Želite nadaljevati?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Nadaljuj",
                cancelButtonText: "Prekliči",
            })
            .then(val => {
                if (val.isConfirmed) {
                    this.loading = true;
                    ApiService
                        .get(`/streamline/api/order/${this.order.id}/order-file/`)
                        .then(res => {
                            this.formValues = this.parseOrder(res.data);
                            this.order = res.data;
                        })
                        .catch(error => {
                            Swal({
                                title: "Napaka pri uvozu naročilnice",
                                text: "Preverite, če ste naložili pravilno datoteko in poskusite ponovno.",
                                icon: "error",
                            })
                            throw error;
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                }
            })

        },

        prefillUserData: function() {
            ApiService
                .get("/api/v1/current-user")
                .then(res => {
                    if (res.data) {
                        this.formValues.client_title = `${res.data.first_name} ${res.data.last_name}`;
                        this.formValues.client_email = res.data.email;
                    }
                })
        },

        //
        // OrderItems
        //
        openOrderItemDetailsModal: function(order_item_id) {
            this.selected_order_item_id = order_item_id;
            this.$modal.show("orderItemDetailsModal");
        },
    },
    mounted() {
        if (this.orderId) {
            this.getOrder();
        } else {
            this.prefillUserData();
        }
    },
}
</script>
