<template>
    <div>
        <div v-if="loading" class="panel panel-default text-center">

            <div class="panel-heading">
              {{ $t('aa:acts')}}
            </div>

            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <div class="row">
                        <p>{{ $t('aa:acts:collect')}}</p>
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>

        </div>

        <div v-else-if="acts" class="panel panel-default">

            <div class="panel-heading">
                {{acts.property_analysis.real_estate.re_key}} - {{acts.property_analysis.real_estate.summary}}
                <div class="panel-action">
                    <a class="btn btn-default pull-right" @click.prevent="getActs(true)">{{ $t('aa:acts:re_collect')}}</a>
                </div>
            </div>

            <div class="panel-wrapper collapse in">
                <div class="panel-body">

                    <div class="row">
                        <div class="col-md-12 spatial-acts">
                            <h3>{{ $t('aa:municipality:acts')}}</h3>
                            <div v-for="(act, idx) in retrieveActs('obcinski_prostorski_akti')" :key="idx">
                                <a :href="act" target="_blank">{{act}} <i class="fa fa-external-link"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-7 spatial-acts overflow-auto">

                            <h3>{{ $t('aa:permit:construction')}}</h3>
                            <administrative-acts-table
                                :acts="retrieveActs('gradbena_dovoljenja')"
                                :editable="true"
                                @save="saveActsData"
                            />

                            <h3>{{ $t('aa:permit:usage')}}</h3>
                            <administrative-acts-table
                                :acts="retrieveActs('uporabna_dovoljenja')"
                                :editable="true"
                                @save="saveActsData"
                            />

                        </div>

                        <div class="col-md-5 spatial-acts">
                            <k-wms-leaflet-map
                                :center="center"
                                :extraLayers="acts_layers"
                                @click="handleClick"
                                ref="map"
                                :fullscreen="true"
                                :zoom="18"
                                :maxZoom="20"
                            >

                            <template v-slot:markers>

                                <l-marker
                                    v-if="map_clicked_acts"
                                    :lat-lng="map_clicked_acts.gps_point.coordinates"
                                    ref="clickmarker"
                                >

                                    <l-popup
                                        :options="{className: 'acts-custom-leaflet-popup'}"
                                        ref="popup"
                                    >
                                        <span v-if="map_clicked_acts.data">
                                            <h3>{{ $t('aa:permit:construction')}}</h3>
                                            <administrative-acts-vertical-table
                                                v-if="map_clicked_acts.data.gradbena_dovoljenja.length"
                                                :acts="map_clicked_acts.data.gradbena_dovoljenja"
                                            />
                                            <div v-else>{{$t('aa:permit:construction:not_available')}}</div>
                                            
                                            <h3>{{ $t('aa:permit:usage')}}</h3>
                                            <administrative-acts-vertical-table
                                                v-if="map_clicked_acts.data.uporabna_dovoljenja.length"
                                                :acts="map_clicked_acts.data.uporabna_dovoljenja"
                                            />
                                            <div v-else class="m-b-20">{{ $t('aa:permit:usage:not_available')}}</div>
                                        </span>

                                        <span v-else>
                                            <p>{{ $t('general:loading')}}</p>
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </span>
                                    </l-popup>

                                </l-marker>
                            
                            </template>

                            </k-wms-leaflet-map>
                            <div class="p-0 text-center"><slot name="mapRights"></slot></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="panel panel-default">

            <div class="panel-heading">
              {{ $t('aa:acts')}}
                <div class="panel-action">
                    <a class="btn btn-default pull-right" @click.prevent="getActs(true)">{{ $t('aa:acts:re_collect')}}</a>
                </div>
            </div>

            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <div class="row">
                        <p>{{ $t('general:error2')}}</p>
                    </div>
                </div>
            </div>

        </div>

        <notifications 
            group="warn"
        />

    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"

import AdministrativeActsTable from './AdministrativeActsTable'
import AdministrativeActsVerticalTable from './AdministrativeActsVerticalTable'

import KWMSLeafletMap from '@/components/k-leaflet-map/KWMSLeafletMap'
import { LMarker, LPopup } from 'vue2-leaflet';


export default {
    props: ['property_id'],
    components: {
        AdministrativeActsVerticalTable,
        AdministrativeActsTable,
        LMarker,
        LPopup,
        "k-wms-leaflet-map": KWMSLeafletMap
    },
    data: function () {
        return {
            loading: true,
            acts: null,
            map_clicked_acts: null,

            // API urls
            acts_url: "/amas/analysis/analysis/api/acts/" + this.property_id,
            map_acts_url: "/amas/real-estate/api/acts",
        
            acts_layers: [
                {
                    name: 'Centroidi upravnih aktov',
                    layers: 'SI.MOP.GRAD:UPRAVNI_AKTI',
                    transparent: true,
                    url: 'https://storitve.eprostor.gov.si/ows-pub-wms/wms?',
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: 'Parcele upravnih aktov',
                    layers: 'SI.MOP.GRAD:UPRAVNI_AKTI_PARCELE',
                    transparent: true,
                    url: 'https://storitve.eprostor.gov.si/ows-pub-wms/wms?',
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
            ]
        }
    },
    methods: {
        getActs: async function(force_update=false) {
            this.loading = true;
            ApiService
                .get(this.acts_url, {
                    params: {
                        force_update: force_update
                    }
                })
                .then(response => {
                    this.acts = response.data;
                })
                .catch(error => {
                    this.$notify({
                        group: 'warn',
                        type: 'warn',
                        title: 'Opozorilo',
                        text: (error.response && error.response.data) ? error.response.data.message : "Napaka pri pridobivanju upravnih aktov",
                    });
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        saveActsData: async function() {
            ApiService
                .post(this.acts_url, this.acts)
                .catch(error => {
                    this.$notify({
                        group: 'warn',
                        type: 'warn',
                        title: 'Opozorilo',
                        text: (error.response && error.response.data) ? error.response.data.message : "Napaka pri shranjevanju upravnih aktov",
                    });
                    throw error;
                })
        },
        handleClick: async function(e) {
            let coordinates = [e.latlng.lat, e.latlng.lng];
            this.map_clicked_acts = {
                gps_point: {"type": "Point", "coordinates": coordinates},
                data: null
            };
            this.$nextTick(() => {this.$refs.clickmarker.mapObject.openPopup();});

            ApiService
                .get(this.map_acts_url, {
                    params: {
                        point: { "type": "Point", "coordinates": [coordinates[1], coordinates[0]] }
                    }
                })
                .then(response => {
                    this.map_clicked_acts.data = response.data;
                })
                .catch(error => {
                    this.$notify({
                        group: 'warn',
                        type: 'warn',
                        title: 'Opozorilo',
                        text: (error.response && error.response.data) ? error.response.data.message : "Napaka pri pridobivanju upravnih aktov",
                    });
                    throw error;
                })
        },
        retrieveActs: function(key) {
            if(!this.acts || !this.acts.result){
                return [];
            }
            return this.acts.result[key];
        }
    },
    mounted() {
        this.getActs();
    },
    computed: {
        center() {
            let center
            if(this.acts && this.acts.property_analysis.real_estate.gps_point){
                let coords = this.acts.property_analysis.real_estate.gps_point.coordinates;
                center = {lat: coords[1], lng: coords[0]}
            }
            return center;
        },
    }
}
</script>

<style scoped>
.spatial-acts {
    margin-bottom: 1.5em;
}

.acts-custom-leaflet-popup {
    width: 300px;
}

.acts-custom-leaflet-popup .leaflet-popup-content{
    width: auto;
    margin: 5px;
}
</style>