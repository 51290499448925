<template>
    <base-input-field
        v-model="localValue"
        :placeholder="placeholder"
        :inputFieldsAddon="inputFieldsAddon"
        :inputFieldsPrepend="inputFieldsPrepend"
        :validator="validator"
        :formatter="formatter"
        :deformatter="deformatter"
        :size="size"
        :disabled="disabled"
    />
</template>

<script>
import BaseInputField from "./BaseInputField.vue";

export default {
    props: {
        value: {
            required: true
        },
        placeholder: {
            required: false
        },
        inputFieldsAddon: {
            type: String
        },
        inputFieldsPrepend: {
            type: String
        },
        size: {
            type: String,
        },
        thousandsSeparator: {
            type: String,
            default: ".",
        },
        min: {
            type: Number,
        },
        max: {
            type: Number,
        },
        disabled: {
            required: false,
            default: false
        },
    },
    components: {
        BaseInputField
    },
    methods: {
        formatNumber: function(num) {
            let clean_num = Number(num);
            if((!clean_num || isNaN(clean_num)) && num != 0){ return num; }
            let res = String(clean_num).replace(".", ",")

            let regex;
            if(res.includes(",")) {
                regex = /(\d)(?=(\d{3})+(?!\d),)/g;
            } else {
                regex = /(\d)(?=(\d{3})+(?!\d))/g;
            }
            return res.replace(regex, `$1${this.thousandsSeparator}`)
        },
        validator: function(val) {
            let valid = val===null || !isNaN(Number(val));
            if (this.min != null) { valid = valid && val >= this.min}
            if (this.max != null) { valid = valid && val <= this.max}
            return valid;
        },
        formatter: function(val) {
            return this.formatNumber(val);
        },
        deformatter: function(val) {
            if (val==="" || val===null) {return null}
            let num = String(val).replaceAll(".", "").replace(",", ".");
            return Number(num);
        },
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
    },
}
</script>
