<template>
    <div  class="row">
        <div class="col-md-4 ">
        <form method="post" novalidate>
            <div class="text-center">
                <h2 v-if="factorTypeExists">
                  {{ $t('avm:factor:type:edit')}}
                </h2>
                <h2 v-else>
                  {{ $t('avm:factor:type:add')}}
                </h2>
            </div>

            <div class="form-group mb-0">
                <div   class="form-group">
                <label
                    class="control-label requiredField"
                >
                  {{ $t('avm:factor:type:name')}}<span class="asteriskField">*</span>
                </label>
                <div class="controls">
                    <input type="text" v-model="factor_type.name"  class="textinput textInput form-control" required="" id="id_adjustment_raw" :disabled="disabled"
                    />
                </div>
                </div>
            <div class=" form-group  mb-0">
                <div class="form-group">
                <label
                    class="control-label requiredField"
                >
                    {{ $t('avm:factor:type:price')}}<span class="asteriskField">*</span>
                </label>
                <div class="controls">
                    <input type="text" v-model="factor_type.original_value"  class="textinput textInput form-control" required="" id="id_adjustment_raw" :disabled="disabled"
                    />
                </div>
                </div>
            </div>
            </div>

            <div class="pull-right p-20">
                <button style="margin-right:5px;" v-if="factorTypeExists" type="submit" class="btn btn-danger" v-on:click.prevent="deleteFactorType()" >
                  {{ $t('general:delete')}}
                </button>
                <button v-if="factorTypeExists" type="submit" class="btn btn-info" v-on:click.prevent="updateOrCreateFactorType()" >
                  {{ $t('general:save')}}
                </button>
                <button v-else type="submit" class="btn btn-info" v-on:click.prevent="updateOrCreateFactorType()" >
                  {{ $t('avm:factor:type:create')}}
                </button>
            </div>

        </form>
      </div>
    </div>

</template>

<script>
import ApiService from "@/components/utils/api.service.js"


export default {
  props: {
    factor_type: Object,
    analysis_session: Number,
    disabled: {
            required: false,
            default: false
    },
  },

  computed: {
    postableFactor: function() {
      return {
        ...this.factor_type,
        analysis_session: this.analysis_session
      }
    },
    factorTypeExists: function () {
      return this.factor_type.id != null;
    },

  },
  methods: {
      
      createFactorType(){
        console.log(this.postableFactor);
        let url = this.url;
        ApiService
        .post(url, this.postableFactor)
        .then(response => console.log(response))
        .catch(error => {
            console.error(error);
        });
      },
      updateFactorType(){
          let url = this.url + this.factor_type.id;
          console.log(this.postableFactor)
          ApiService
          .put(url, this.postableFactor)
          .then(response => console.log(response))
          .catch(error => {
              console.error(error);
          });
          this.$emit("update-factortype-complete");

         },
      deleteFactorType(){
            let url = this.url + this.factor_type.id;
            ApiService
            .delete(url, this.adjustment)
            .then(response => console.log(response))
            .catch(error => {
                console.error(error);
            });

          this.$emit("update-factortype-complete");
      },
      updateOrCreateFactorType(){
        if (this.factorTypeExists){
          this.updateFactorType();
        } else {
          this.createFactorType();
        }
        this.$emit("update-factortype-complete");

      },
  },
  data() {
    return {
      url: '/amas/analysis/avm/api/factor-types/',

    }
  },
}
</script>
