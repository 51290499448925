<template>
    <div>
        <h3 v-if="title">{{title}}</h3>
        
        <table class="table table-condensed">
            <thead>
                <tr>
                    <!-- <th class="text-center">ID upravnega akta</th> -->
                    <th class="text-center">{{ $t('aa:number')}}</th>
                    <th class="text-center">{{ $t('aa:executive_body')}}</th>
                    <th class="text-center">{{ $t('aa:procedure')}}</th>
                    <th class="text-center">{{ $t('aa:building:description')}}</th>
                    <th class="text-center">{{ $t('aa:date:issued')}}</th>
                    <th class="text-center">{{ $t('aa:date:legally')}}</th>
                    <th class="text-center">{{ $t('aa:parcel')}}</th>
                    <th class="text-center" v-if="editable">{{ $t('aa:options')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-for="(permit, index) in acts"
                    :key="permit.STEV_ZAD"
                >
                    <!-- <td class="text-center">{{permit.ID_UA_L}}</td> -->
                    <td class="text-center">
                        <k-editable
                            v-if="editable"
                            v-model="permit.STEV_ZAD"
                            default-if-none="/"
                            @close="$emit('save', $event)">
                        </k-editable>
                        <span v-else>{{permit.STEV_ZAD}}</span>
                    </td>
                    <td class="text-center">
                        <k-editable
                            v-if="editable"
                            v-model="permit.NAZ_UPR_ORG"
                            default-if-none="/"
                            @close="$emit('save', $event)">
                        </k-editable>
                        <span v-else>{{permit.NAZ_UPR_ORG}}</span>
                    </td>
                    <td class="text-center">
                        <k-editable
                            v-if="editable"
                            v-model="permit.NAZ_UPR_POS"
                            default-if-none="/"
                            @close="$emit('save', $event)">
                        </k-editable>
                        <span v-else>{{permit.NAZ_UPR_POS}}</span>
                    </td>
                    <td class="text-center">
                        <k-editable
                            v-if="editable"
                            v-model="permit.OBJ"
                            default-if-none="/"
                            @close="$emit('save', $event)">
                        </k-editable>
                        <span v-else>{{permit.OBJ}}</span>
                    </td>
                    <td class="text-center">
                        <k-editable
                            v-if="editable"
                            v-model="permit.DAT_IZD"
                            default-if-none="/"
                            @close="$emit('save', $event)">
                        </k-editable>
                        <span v-else>{{permit.DAT_IZD}}</span>
                    </td>
                    <td class="text-center">
                        <k-editable
                            v-if="editable"
                            v-model="permit.MAX_DAT_PRA"
                            default-if-none="/"
                            @close="$emit('save', $event)">
                        </k-editable>
                        <span v-else>{{permit.MAX_DAT_PRA}}</span>
                    </td>
                    <td class="text-center">
                        <div 
                            v-for="parcel in permit.parcels_same_stev_zad"
                            :key="parcel.re_key"
                        >
                            <a class="open-modal" 
                                :href="getOpenPropertyModalUrl(parcel.re_key)">
                                {{parcel.re_key}}
                            </a>
                        </div>
                    </td>
                    <td class="text-center" v-if="editable">
                        <span v-tooltip="$t('aa:tooltip:remove_from_access')" 
                            class="btn btn-xs btn-outline btn-rounded btn-danger m-b-10"
                            v-on:click.prevent="removeAct(index)">
                                {{$t('general:remove')}}
                        </span>
                    </td>
                </tr>
                <tr v-if="editable">
                    <td class="text-center" colspan="7">
                        <a type="button" class="btn btn-info btn-xs" @click.prevent="openAddActModal()">
                            {{$t('general:add')}} <i class="fa fa-plus"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <modal 
            :name="`add-act-modal-${_uid}`"
            height="auto"
        >
            <div class="p-20">
                <h2>{{ $t('aa:acts:add')}}</h2>
                <hr>
                <form>
                    <div class="form-group">
                        <label>{{ $t('aa:number')}}</label>
                        <input type="text" class="form-control" v-model="new_act.STEV_ZAD">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:executive_body')}}</label>
                        <input type="text" class="form-control" v-model="new_act.NAZ_UPR_ORG">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:procedure')}}</label>
                        <input type="text" class="form-control" v-model="new_act.NAZ_UPR_POS">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:building:description')}}</label>
                        <input type="text" class="form-control" v-model="new_act.OBJ">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:date:issued')}}</label>
                        <input type="text" class="form-control" v-model="new_act.DAT_IZD">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:date:legally')}}</label>
                        <input type="text" class="datepicker form-control" v-model="new_act.MAX_DAT_PRA">
                    </div>
                    <div class="pull-right p-20">
                        <button type="submit" class="btn btn-info" v-on:click.prevent="addAct()">{{ $t('general:add')}}</button>
                        <button type="submit" class="btn btn-default btn-outline" v-on:click.prevent="closeAddActModal()">{{ $t('general:cancel:btn')}}</button>
                    </div>
                </form>
            </div>
        </modal>
    </div>
</template>

<script>
import KEditable from "@/components/k-editable/KEditable";

export default {
    props: ['acts', 'title', 'editable'],
    components: {
        KEditable
    },
    data: function () {
        return {
            new_act: {}
        }
    },
    methods: {
        openAddActModal: function() {
            this.$modal.show('add-act-modal-' + this._uid);
        },
        closeAddActModal: function() {
            this.$modal.hide('add-act-modal-' + this._uid);
        },
        addAct: function() {
            this.acts.push(this.new_act);
            this.new_act = {}
            this.closeAddActModal();
            this.$emit("save");
        },
        removeAct: function(idx) {
            console.log(idx);
            this.acts.splice(idx, 1);
            this.$emit("save");
        }
    },
}
</script>
