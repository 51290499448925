<template>
  <div class="row">
    <div class="col-md-4 col-md-offset-4">
      <div class="text-center">
        <p>
          {{ $t('avm:size:edit')}}:
        </p>
      </div>
      <form method="post" novalidate>
        <div class="text-center">
          <div id="div_id_value" class="form-group" >
            <label for="id_value" class="control-label">
              {{ $t('avm:size:size')}}
            </label>
            <div class="controls">
              <input type="number" v-model="transaction.custom_size" class="textinput textInput form-control" id="id_value" :placeholder="dynamicPlaceholder"/>
            </div>
            <label for="id_comment" class="control-label">
              {{ $t('avm:size:explanation')}}
            </label>
            <div class="controls">
              <textarea v-model="transaction.custom_size_note" cols="40" rows="10" class="textarea form-control" required="" id="id_comment"
              ></textarea>
            </div>
          </div>
  
        
          <div class="pull-right p-20">
            <button type="submit" class="btn btn-info" v-on:click.prevent="updateTransactionSize()" >
              {{ $t('avm:size:adjust')}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";


export default {
  props: {
    transaction: Object,
  },

  computed: {
    dynamicPlaceholder() {
      return this.transaction.size;
    },
    isCustomSizeEmpty() {
      return this.transaction.custom_size === null;
    }

  },
  methods: {
      
      updateTransactionSize(){
        if (!this.isCustomSizeEmpty && (this.transaction.custom_size != this.transaction.size)){
          let url = this.url + this.transaction.id;
          ApiService
          .put(url, this.transaction)
          .then(response => {
            this.$emit("update-size-complete", true);
            })
          .catch(error => {
              Swal({
                    title: "",
                    text: this.$t('avm:error:msg'),
                    icon: "error",
                    })

              this.$emit("update-size-complete", false);
              throw error;
          });
        }
        else {
          this.$emit("size-remains-same");
        }
      }
  },
  data() {
    return {
      url: '/amas/analysis/avm/api/transaction-size/',

    };
  },
};
</script>
