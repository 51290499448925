<template>
    <filtered-table
        :items="items"
        :loading="loading"
        :columns="columns_joined"
    >
        <template v-slot:id="{ item }">
            Naročilo {{ item.id }}
            <div class="text-muted">
                <span v-tooltip="'Datum naročila'" v-if="item.created_at">
                    {{ item.created_at }}
                </span>
            </div>
        </template>

        <template v-slot:status="{ item }">
            <span :class="getStatusBadgeClass(item.status)" style="font-size: 10px;">
                {{ item.get_status_display }}
            </span>
        </template>

        <template v-slot:client="{ item }">
            <div>
                <span v-tooltip="'Naziv naročnika'" v-if="item.client_title">
                    {{ item.client_title }}
                </span>
            </div>
            
            <span class="text-muted" v-tooltip="'eMail naslov naročnika'" v-if="item.client_email">
                <i class="fa fa-envelope"></i>
                {{ item.client_email }}
            </span>
        </template>

        <template v-slot:appraisals_amount="{ item }">
            {{ item.orderitem_set.length }}
        </template>

        <template v-slot:due_dates="{ item }">
            <div>
                <span v-tooltip="'Rok za oddajo ponudb'" v-if="item.quote_due_date">
                    <i class="fa fa-inbox"></i>
                    {{ item.quote_due_date }}
                </span>
            </div>
            <div>
                <span v-tooltip="'Rok za oddajo cenitve'" v-if="item.due_date">
                    <i class="fa fa-paper-plane"></i>
                    {{ item.due_date }}
                </span>
            </div>
        </template>

        <template v-slot:offers_amount="{ item }">
            {{ item.offers_amount }}
        </template>

        <template v-slot:appraiser="{ item }">
            <span v-tooltip="'Naziv cenilca'" v-if="item.appraiser">
                {{ item.appraiser.first_name }} {{ item.appraiser.last_name }}
            </span>
            <span v-else></span>
        </template>

        <template v-for="(inx, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
    </filtered-table>
</template>

<script>
import FilteredTable from "./FilteredTable";
import tablemixin from "./mixins/tablemixin";

export default {
    mixins: [ tablemixin ],
    components: {
        FilteredTable,
    },
    data() {
        return {
            base_columns: [
                {
                    field: "id",
                    label: "ID naročila",
                    slot_name: "id",
                },
                {
                    field: "get_status_display",
                    label: "Status",
                    slot_name: "status",
                    filter: {
                        type: "multiselect",
                        value: [],
                    },
                },
                {
                    field: "client",
                    label: "Naročnik",
                    slot_name: "client",
                    sort_conversion: item => item.client_title,
                },
                {
                    field: "appraisals_amount",
                    label: "Število cenitev",
                    slot_name: "appraisals_amount",
                    sort_conversion: item => item.orderitem_set.length,
                    filter: {
                        type: "number",
                        value: {
                            "from": null,
                            "to": null,
                        },
                    },
                },
                // {
                //     field: "client_title",
                //     label: "Naročnik",
                //     filter: {
                //         type: "multiselect",
                //         value: [],
                //     },
                // },
                // {
                //     field: "client_email",
                //     label: "Email naročnika",
                //     filter: {
                //         type: "multiselect",
                //         value: [],
                //     },
                // },
                // {
                //     field: "created_at",
                //     label: "Datum naročila",
                //     filter: {
                //         type: "date",
                //         value: {
                //             "from": null,
                //             "to": null,
                //         },
                //     },
                // },
                {
                    field: "due_dates",
                    label: "Rok ponudbe & izvedbe",
                    slot_name: "due_dates",
                    sort_conversion: item => item.due_date,
                },
                // {
                //     field: "due_date",
                //     label: "Rok izvedbe",
                //     filter: {
                //         type: "date",
                //         value: {
                //             "from": null,
                //             "to": null,
                //         },
                //     },
                // },
                // {
                //     field: "quote_due_date",
                //     label: "Rok za oddajo ponudbe",
                //     filter: {
                //         type: "date",
                //         value: {
                //             "from": null,
                //             "to": null,
                //         },
                //     },
                // },
                {
                    field: "offers_amount",
                    label: "Število ponudb",
                    slot_name: "offers_amount",
                    sort_conversion: item => item.offers_amount,
                    filter: {
                        type: "number",
                        value: {
                            "from": null,
                            "to": null,
                        },
                    },
                },
                {
                    field: "appraiser",
                    label: "Izbrani cenilec",
                    slot_name: "appraiser",
                },
                {
                    field: "statistics",
                    label: "Statistika",
                    sortable: false,
                    hidden: true,
                },
                {
                    field: "actions",
                    label: "Akcija",
                    sortable: false,
                    slot_name: "actions",
                },
            ],
        }
    }
}
</script>
